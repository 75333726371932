import React, { forwardRef, useEffect, useRef, useState, useTransition } from "react";
import logo from "../../../assets/wallonia.png";
import FakeLoadingBar from "../FakeLoadingBar";
import { Progress } from "antd";
import "./Loader.css";
import { CSSTransition } from "react-transition-group";
import { LazyWait } from "../Lazy";

export const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 20,
      }}
    >
      <img style={{ display: "block" }} width={100} alt="logo" src={logo} />
      <FakeLoadingBar
        expectedTime={700}
        start
        done={false}
        render={(progress) => <Progress style={{ width: 200 }} percent={progress} strokeColor="red" showInfo={false} />}
      />
    </div>
  );
};

export const LoaderScreen = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div
      ref={ref}
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#F0F0F0",
        zIndex: 1500,
      }}
    >
      <div>
        <Loader />
      </div>
    </div>
  );
});

const Fallback = ({ onMount, onUnmount }) => {
  useEffect(() => {
    onMount();
    return () => onUnmount();
  }, [onMount, onUnmount]);
  return null;
};
export const FadeLoaderScreen: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>();
  const nodeRef = useRef(null);
  return (
    <>
      <CSSTransition nodeRef={nodeRef} in={loading} classNames="loader" timeout={100} unmountOnExit exit={!loading}>
        <LoaderScreen ref={nodeRef} />
      </CSSTransition>
      <React.Suspense fallback={<Fallback onMount={() => setLoading(true)} onUnmount={() => setLoading(false)} />}>
        <LazyWait ok={loading !== undefined} />
        {children}
      </React.Suspense>
    </>
  );
};

export default FadeLoaderScreen;
