import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";

export default function Methodologie() {
  //const [event, setEvent] = useContext(EventContext);

  return (
    <div>
      <h4>Que faire ?</h4>
      <p>
      La priorité est de tout mettre en oeuvre pour préserver la biodiversité. Toutes les mesures évoquées sur la <a href="">[page Réseau Ecologique]</a> sont donc a mettre en oeuvre de manière prioritaire.
      </p>
      <p>
      Il est difficile de prescrire de manière concrète ce qui peut être mis en place pour les activité humaine bénéficiant d'un ou plusieurs services écosystémiques qui vont être affectés. 
      De manière globale on peut réfléchir aux pistes de sobriété et de diversification qui permettront de pallier à la diminution du service écosystémique sans accroitre la pression sur l'écosystème qui le fournit.
      </p>  
    </div>
  );
}
