import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";

export default function Methodologie() {
  //const [event, setEvent] = useContext(EventContext);

  return (
//Décrire les mesures d'adaptation possible en lien avec les vulnérabilités explorées sur les cartes
    <div>
      <p>
      Notre connaissance actuelle des risques liés aux ICU dans les villes et pour les personnes vulnérables peut encore être affinée aujourd’hui. Une étude de modélisation détaillée du phénomène d’ICU pour une série de grandes villes wallonnes, avec un accent sur la cartographie des risques et des personnes vulnérables, apparaît opportune. La modélisation détaillée de l’ICU peut se faire au travers de sciences participatives, avec l’appui ou même à l’initiative des citoyens. 
      <a href="">[(Voir fiche mesure XX)]</a>
      Plus concrètement, le confort thermique en ville à l’extérieur doit être maintenu pour éviter à la population de souffrir de la chaleur. La végétalisation des centres urbains, en ce compris l'intégration des réseaux écologiques, est un levier essentiel pour y parvenir. La création de parcs et la plantation d’arbres, qui contribuent le plus à l’amélioration du confort thermique en extérieur, doit désormais être intégré aux pratiques habituelles en matière d’aménagement du territoire. 
      <a href="">[(Voir fiche mesure XX)]</a>
      Avec la hausse attendue des températures, l’usage de la climatisation sera plus communément répandu.  Il y a donc lieu d’imposer des contraintes urbanistiques pour lutter contre la surchauffe à l’intérieur des bâtiments. Listons à titre d’exemple plusieurs moyens efficaces qui le permettraient comme refroidir les toitures, installer des dispositifs d’ombrage ou encore renforcer le bâti de couleur blanche.  
      <a href="">[(Voir fiche mesure XX)]</a>
      Renforcer sa capacité d’adaptation constitue également un levier pour réduire sa vulnérabilité à un aléa. C’est pourquoi, il est nécessaire de planifier la gestion d’un événement comme une canicule via l’élaboration d’un plan chaleur pour les bâtiments en zone à risque d’ICU et composant avec des personnes sensibles. Celui-ci prescrira, à l’échelle de la Wallonie, les mesures préventives à activer au niveau des bâtiments susmentionnés et les mesures concrètes à prendre comme annuler ou postposer des manifestations de masse (sportives, culturelles ou autres) afin de limiter les effets néfastes de la forte chaleur.
      <a href="">[(Voir fiche mesure XX)]</a>
      </p>
     
    </div>
  );
}
