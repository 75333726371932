import React from "react";
import { useTheme } from "../../../hooks/theme";
import { Row, Col, Card, Tag, Divider } from "antd";
import { InfoCircleOutlined, LogoutOutlined } from "@ant-design/icons";
import { useThemes } from "../../../hooks/staticThemes";
import { ThemeButton } from "../Utils/ThemePicker";

const SidePanelImpact = () => {
  const [theme] = useTheme();
  const { data: themes = [] } = useThemes();
  return (
    <div>
      {theme && (
        <div>
          <div style={{ padding: 16 }}>
            <Card
              size="small"
              style={{ backgroundColor: "#e1f3ff" }}
              title="ALEA CLIMATIQUE"
              extra={
                <a href="#">
                  <InfoCircleOutlined />
                </a>
              }
            >
              {theme.aleas &&
                theme.aleas.map((a) => (
                  <Tag key={a.name} color="#afe0ff" style={{ color: "black", marginTop: 4 }}>
                    {a.name}
                  </Tag>
                ))}
            </Card>
            <Row gutter={8} style={{ marginTop: 8 }}>
              <Col span={12}>
                <Card
                  size="small"
                  style={{ backgroundColor: "#fee9c9" }}
                  title="EXPOSITION"
                  extra={
                    <a href="#">
                      <InfoCircleOutlined />
                    </a>
                  }
                >
                  {theme.expositions &&
                    theme.expositions.map((a) => (
                      <Tag
                        key={a.name}
                        color="#ffd08a"
                        //@ts-ignore
                        style={{ color: "black", marginTop: 4, cursor: a.refId ? "pointer" : "" }}
                        //@ts-ignore
                        onClick={() => document.getElementById(a.refId).scrollIntoView({ behavior: "smooth", block: "center" })}
                      >
                        {/* @ts-ignore */}
                        {a.name} {a.refId ? <LogoutOutlined /> : ""}
                      </Tag>
                    ))}
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  size="small"
                  style={{ backgroundColor: "#e0ffb8" }}
                  title="VULNERABILITE"
                  extra={
                    <a href="#">
                      <InfoCircleOutlined />
                    </a>
                  }
                >
                  {theme.vulnérabilités &&
                    theme.vulnérabilités.map((a) => (
                      <Tag key={a.name} color="#aded5d" style={{ color: "black", marginTop: 4 }}>
                        {a.name}
                      </Tag>
                    ))}
                </Card>
              </Col>
            </Row>
            <Card
              size="small"
              style={{ backgroundColor: "#d9d9d9", marginTop: 8 }}
              title="IMPACT"
              extra={
                <a href="#">
                  <InfoCircleOutlined />
                </a>
              }
            >
              {theme.impacts &&
                theme.impacts.map((a) => (
                  <Tag key={a.name} color="#a6a6a6" style={{ color: "black", marginTop: 4 }}>
                    {a.name}
                  </Tag>
                ))}
            </Card>
          </div>
          <Divider orientation="left">Introduction</Divider>
          <div style={{ padding: 16 }}>{theme.content}</div>
          {theme.methodologie && (
            <div>
              <Divider orientation="left">Méthodologie d’analyse de vulnérabilité</Divider>
              <div style={{ padding: 16 }}>{theme.methodologie}</div>
            </div>
          )}
          {theme.données && (
            <>
              <Divider orientation="left">Données</Divider>
              <div style={{ padding: 16 }}>{theme.données}</div>
            </>
          )}
          {theme.adaptation && (
            <>
              <Divider orientation="left">Adaptation</Divider>
              <div style={{ padding: 16 }}>{theme.adaptation}</div>
            </>
          )}
          <Divider orientation="left">Liens avec les autres impacts </Divider>
          <Row>
            {theme.associatedThemes &&
              themes
                //@ts-ignore
                .filter((t) => theme.associatedThemes.includes(t.id))
                .map((t) => {
                  return <ThemeButton key={t.id} id={t.id} label={t.label} icon={t.icon} />;
                })}
          </Row>
        </div>
      )}
    </div>
  );
};

export default SidePanelImpact;
