import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";

export default function Methodologie() {
  //const [event, setEvent] = useContext(EventContext);

  return (
    <div>
      <h4>Que faire ?</h4>
      <p>
      Renforcer le réseau écologique est la première priorité pour renforcer les capacités d'adaptation de la biodiversité wallonne face aux changements climatiques et, indirectement, renforcer la résilience globale du territoire.
      Ceci peut se faire à tous les échellons.
      </p>
      <p>
      Si vous êtes propriétaire ou gestionnaire d'une ou plusieurs parcelles en Wallonie, observez sur la carte où se trouvent ces parcelles par rapport au réseau écologique wallon et comment les zones les plus proche du réseau évolueront dans les scénarios futurs. 
      Il s'agit ensuite d'adopter les mesures de gestion qui permettent de <a href="">[(Voir fiche mesure XX)]</a> :
      <ul>
        <li>renforcer la qualité de la biodiversité sur les parcelles</li>
        <li>compléter le réseau écologique en participant au développement d'une zone coeur ou d'un corridor,</li>
        <li>supprimer une barrière qui empêchent la circulation des espèces et habitats dans le réseau</li>
      </ul>
      En parallèle il est crucial de donner le statut de protection de la nature le plus fort possible aux parcelle en fonction des affectations de celles-ci.
      </p>
      <p>
       Si vous êtes une commune, il est important d'élaborer ou  mettre à jour le réseau écologique de votre territoire et d'initier une démarche participative permettant de rassembler l'énergie citoyenne et les différents acteurs et propriétaires fonciers de la commune qui peuvent contribuer à l'amélioration du réseau écologique. Ceci peut par exemple se faire dans le cadre des Plans Communaux de Développement de la Nature (PCDN). <a href="">[(Voir fiche mesure XX)]</a>
      </p>
      <p>Le réseau écologique ne doit pas être vu comme le seul outil de protection de la nature mais plutôt comme un outil de priorisation afin d’orienter les choix d’allocation des ressources. On peut ainsi maximiser les effets bénéfiques pour renforcer les possibilités de la biodiversité à se déplacer dans un contexte très anthropisé et de préserver les services écosystémiques qui renforcent le plus la résilience de notre territoire face aux changements climatiques.  
        En dehors du réseau écologique des mesures de protection de la biodiversité peuvent être menées et avoir beaucoup de pertinence. 
        </p>
        <p>Outre le réseau écologique, il est important de réduire les autres pressions sur les écosystèmes, telles que la surexploitation, la pollution, la fragmentation et les espèces exotiques envahissantes (Secretariat of the Convention on Biological Diversity, 2009). 
      Les espèces exotiques envahissantes notamment ont été désignées par l’IPBES comme étant une des causes principales de perte de la biodiversité. Il est donc crucial de mettre en place des programmes de détection et de gestion rapide des espèces exotiques envahissantes émergentes, une gestion efficace n’étant possible qu’en début d’invasion. <a href="">[(Voir fiche mesure XX)]</a>
      Des programmes de translocation ou de migration assistée peuvent également, en dernier recours, permettre la sauvegarde d’espèces ou de communautés incapables de migrer assez rapidement vers de nouvelles zones favorables, en raison d’une fragmentation trop importante des habitats ou de vitesses de dispersion trop lentes. <a href="">[(Voir fiche mesure XX)]</a>
      Au niveau forestier, de nombreuses guidelines régionales et européennes existent déjà pour augmenter la résilience des écosystèmes forestiers face au changement climatique, tout en favorisant la biodiversité. Ces mesures consistent, entre autres, à diversifier les essences, favoriser une sylviculture plus proche de la nature en favorisant la régénération naturelle, tester la migration assistée d’essences et de provenances. <a href="">[(Voir fiche mesure XX)]</a>
      </p>  
    </div>
  );
}
