//supprimer selon pertinence
import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";
import { useLocation } from "../../../components/Thematic/Utils/Location";
export default function Content() {
  //@ts-ignore
  const [event, setEvent] = useContext(EventContext);
  const [location, setLocation] = useLocation();

  return (
    <div>
      <h4>Aléas</h4>
      <p>
        L’impact du changement climatique sur l’emploi et les différents secteurs d’activités sera étudié au regard des trois stress climatiques :
        <ul>
          <li>la hausse tendancielle des températures et les vagues de chaleur;</li>
          <li>les inondations;</li>
          <li>les épisodes de sécheresse.</li>
        </ul>
        Voir respectivement les pages <a href="">Projections</a>, <a href="">Inondations</a> et (voir page <a href="">Sécheresses</a> en ce qui
        concerne les projections climatiques et les facteurs aggravants de ces phénomènes.
      </p>

      <h4>Exposition</h4>
      <p>Nous explorons ici l'exposition des bâtiments des entreprises des secteurs secondaires et tertiaires en Wallonie face à ces aléas.</p>
      <h4>Vulnérabilité</h4>
      <p>
        L'exposition à ces aléas peut être renforcée par différents facteurs de risque, en particulier la localisation du tissu économique local, des
        industries, des PME et du commerce de détail.
      </p>
      <h4>Impacts</h4>
      <p>Pertes économiques, interruptions de la production de biens et services, pertes d'emploi et impact sur la santé des travailleurs.</p>
      <h4>Ce que montrent les cartes</h4>
      <p>
        Sur cette carte vous pouvez explorer les risques qui pèsent sur les entreprises wallonnes. Les scénarios futurs de changements climatiques
        peuvent être sélectionnés en haut de la carte. Il vous faut également sélectionner ci-dessous l'aléa que vous souhaitez explorer :
        <ul>
          <li>Vagues de chaleur</li>
          <li>Inondations</li>
          <li>Sécheresses</li>
        </ul>
        Ainsi que le secteur d'activité :
        <ul>
          <li>Industrie chimique</li>
          <li>Industrie méttalurgique</li>
          <li>Industrie électrique</li>
          <li>Industrie pharmaceutique</li>
          <li>Secteur de la construction</li>
          <li>Commerces de gros et de détail</li>
          <li>Services de conseil, juridique, immobiliers, financiers et assurantiels</li>
          <li>Services informatique et des technologies de l'information et la communication</li>
          <li>Services publics</li>
          <li>Services logistique, de transport et d'entreposage</li>
          <li></li>
        </ul>
        La partie gauche de la carte montre la répartition des entreprises de ce secteur en Wallonie. La partie droite représente le taux de risque
        estimé. Les zones les plus à risque en Wallonie sont représentées en rouge.
      </p>
      <h4>Rapport méthodologique complet</h4>
      <p>
        <a href="">[Fettweiss, R. et al. 2024]</a>
      </p>
    </div>
  );
}
