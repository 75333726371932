import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";

export default function Methodologie() {
  //const [event, setEvent] = useContext(EventContext);

  return (
//Décrire les mesures d'adaptation possible en lien avec les vulnérabilités explorées sur les cartes
    <div>
      <p> 
      Face aux vagues de chaleur, il est nécessaire de végétaliser le centre-ville afin de réduire le phénomène d'ilôt de chaleur urbain.
      Toutefois cette mesure ne suffira pas et doit s'accompagner de mesures de rénovations du parc de logement pour éviter l'installation massive de climatisations (végétalisation, protection des vitrages, matériaux de couleur blanche).
      Des mesures de sensibilisation pour des comportements adaptés sont également pertinentes (hydratation, habillement, ventilation du bâtiment).
      <a href="">[(Voir fiche mesure XX)]</a>
      Pour les inondations, outre toutes les mesures d'adaptation évoquées sur la page <a href="">Inondations</a> pour limiter l'occurence du phénomène, on peut aussi essayer d'augmenter la résilience du bâti au travers de normes urbanistiques.
      Des normes urbanistiques dans les zones à proximité des risques de feu de forêt seront également pertinentes. En parallèle d'une surveillance accrue de ce risque, de systèmes d'alerte précoce et réaction rapide des services d'urgence.
      </p>
     
    </div>
  );
}
