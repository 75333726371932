import { create } from "zustand";

type SidePanelStore = {
  folded: boolean;
  setFolded: (folded: boolean) => void;
};

export const useSidePanelStore = create<SidePanelStore>((set) => {
  return {
    folded: false,
    setFolded: (folded) => set(() => ({ folded })),
  };
});
