import { Progress } from "antd";
import React, { useEffect, useState } from "react";

export const useWait = (seconds: number, dependencies: Array<any> = []) => {
  const [ok, setOk] = useState<boolean>(false);
  useEffect(() => {
    setOk(false);
    const timeout = setTimeout(() => {
      setOk(true);
    }, seconds * 1000);
    return () => clearTimeout(timeout);
  }, [...dependencies]);
  return ok;
};

const FakeLoadingBar: React.FC<{
  expectedTime: number;
  done: boolean;
  start?: boolean;
  render?: (progress: number) => React.ReactNode;
}> = ({ start = true, expectedTime, done, render = (progress) => <Progress percent={progress} /> }) => {
  const [progress, setProgress] = useState<number>(0);
  useEffect(() => {
    if (!start) {
      setProgress(0);
      return;
    }
    if (done) {
      setProgress(100);
      return;
    }
    let mount = true;
    const halTime = expectedTime / 2;
    const startTime = Date.now();
    setProgress(0);
    const interval = setInterval(() => {
      if (mount) {
        const duration = Date.now() - startTime;
        const progress = 100 - 100 * Math.exp((-Math.log(2) * duration) / halTime);
        setProgress(progress);
      }
    }, 30);
    return () => {
      mount = false;
      clearInterval(interval);
    };
  }, [start, expectedTime, done]);

  return <>{render(progress)}</>;
};

export default FakeLoadingBar;
