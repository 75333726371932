//supprimer selon pertinence
import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";
import { usePopulation } from "../../../hooks/population";
import { useLocation } from "../../../components/Thematic/Utils/Location";
export default function Content() {
  //@ts-ignore
  const [event, setEvent] = useContext(EventContext);
  const [location, setLocation] = useLocation();
  const population = usePopulation() || [];

  return (
    <div>
      <h4>Aléa</h4>
      <p>
        Des températures élevées sont souvent accompagnées d’épisodes de hautes concentrations en ozone troposphérique. Il est le produit de réactions
        photochimiques complexes dans lesquelles interviennent des polluants précurseurs tels que les oxydes d’azote (NOx) et les composés organiques
        volatils (COV). Des conditions autres que des températures élevées doivent aussi être remplies pour observer ces pics d’ozone comme un
        rayonnement important (beaucoup d’UV, pas de nuages) ou un vent faible. Les concentrations en particules, quant à elles, dépendent de la
        fréquence et de l'intensité des précipitations - qui « nettoient » l’air de certains polluants (i.e. en les entraînant au sol ou en aidant à
        leur dissolution) - mais aussi de l’épaisseur de la couche de mélange qui diminue avec la stabilité de l’atmosphère. Lorsque les conditions
        sont stables, le mélange vertical est limité. Les niveaux de pollution sont également influencés par la vitesse et la direction des vents
        dominants.
      </p>
      <h4>Facteurs aggravants</h4>
      <p>
        La pollution de l’air, et particulièrement dans les villes, est directement impactée par les principales activités humaines que sont
        l’industrie, le transport et l’agriculture qui libèrent divers polluants atmosphériques tels que les oxydes d'azote (NOx), les particules
        fines (PM2,5 et PM10), les composés organiques volatils (COV) ou encore le dioxyde de soufre (SO2). Le chauffage résidentiel est également une
        source de pollution atmosphérique. Ces pollutions sont exacerbées par une urbanisation croissante qui renforce ces différentes activités.
      </p>
      <h4>Exposition</h4>
      <p>
        Les habitants des villes sont directement exposés à la pollution de l'air en raison de leur proximité avec les sources de pollution. Les zones
        urbaines densément peuplées concentrent les émissions de polluants, et les personnes vivant à proximité des axes routiers voire des usines
        sont exposées à des niveaux élevés de pollution atmosphérique. Cette exposition constante peut avoir des répercussions graves sur la santé,
        surtout chez les individus déjà vulnérables en raison de facteurs tels que l'âge, les conditions médicales préexistantes ou le statut
        socio-économique. En 2019, 6500, 750 et 270 décès prématurés (non cumulables) étaient respectivement attribuables à l'exposition aux
        particules fines (PM2.5), au dioxyde d’azote (NO2) et à l’ozone (O3) en Belgique selon l’Agence Européenne de l’Environnement.
      </p>
      <h4>Vulnérabilité</h4>
      <p>
        Les polluants atmosphériques sont davantage recensés là où se concentrent les activités humaines. C’est pourquoi les populations urbaines sont
        particulièrement vulnérables. Par ailleurs, les enfants, les personnes âgées et celles souffrant de maladies respiratoires ou
        cardiovasculaires, sont plus sensibles aux effets néfastes de la pollution de l'air. De même, les populations plus précaires sont généralement
        davantage exposées aux pollutions atmosphériques car elles résident souvent dans des quartiers situés dans des zones industrielles ou près des
        autoroutes et rencontrent plus de difficulté d’accès à des soins de santé de qualité.
      </p>
      <h4>Impacts</h4>
      <p></p>
      <h4>Ce que montrent les cartes</h4>
      <p>
        La carte ci-contre représente les projections de la qualité de l’air actuelle et future (jusqu’en 2050) pour la région wallonne. Plus
        spécifiquement, ces carte indiquent les projections des concentrations régionales actuelles, futures et les anomalies futur-présent, les
        indices BelAQI correspondants (rassemblant en un seul nombre représentatif les concentrations de plusieurs polluants de l’air ambiant et basé
        entièrement sur les effets santé) ainsi que la comparaison des concentrations simulées avec les valeurs limites européennes actuelles et les
        lignes directrices de l’OMS mises à jour pour identifier les zones à risque. Pour chaque polluant étudié, la fraction de population exposée à
        un dépassement est également calculée.
      </p>
      <h4>Rapport méthodologique complet</h4>
      <p>
        <a href="">[, . et al. 2024]</a>
      </p>
    </div>
  );
}
