//supprimer selon pertinence
import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";
import { usePopulation } from "../../../hooks/population";
import { useLocation } from "../../../components/Thematic/Utils/Location";
export default function Content() {
  //@ts-ignore
  const [event, setEvent] = useContext(EventContext);
  const [location, setLocation] = useLocation();
  const population = usePopulation() || [];

  return (
    <div>
      <h4>Aléa</h4>
      <p>
        En Wallonie, les projections moyennes du futur climatique montrent une augmentation de la température estivale de +1,73°C à +4,72°C et une
        diminution des précipitations estivales de -6% à -17%, une augmentation du nombre de jours secs consécutifs par 14% à 32% et une augmentation
        de l'évapotranspiration également située entre 12% et 31% selon les scénarios à +2°C à +4°C de réchauffement au niveau mondial. Le nombre de
        jours en vigilance canicule, proche de 0 durant la période 1981-2010 augmenterait quant à lui entre 1 et 6 jours par an selon les deux même
        scénarios. Ces changements dans le rapport entre les précipitations et évapotranspiration entraîneront des fluctuations de l'humidité du sol,
        pouvant conduire à des sécheresses édaphiques plus fréquentes et plus marquées{" "}
        <a href="https://www.ipcc.ch/report/ar6/wg1/downloads/report/IPCC_AR6_WGI_SPM_final.pdf">(IPCC, 2021)</a>. De telles sécheresses entraîneront
        des répercussions, en particulier sur la Biodiversité et l’Agriculture, mais également le cycle de l'eau et les infrastructures bâties.
      </p>
      <h4>Facteurs aggravants</h4>
      <p>
        L’artificialisation des sols et la compaction sont des pratiques humaines qui influent sur l'équilibre hydrique des sols, altérant leur
        capacité à retenir l'eau et causant un déficit en eau infiltrée, accentuant ainsi la sécheresse, surtout en période de faibles précipitations.
        Le déséquilibre de l’eau percolée et l’évapotranspiration par le couvert végétal affecte l’humidité du sol et peut être aggravé par les
        pratiques d’irrigation.
      </p>
      <h4>Exposition</h4>
      <p>
        Les tourbières des Hautes Fagnes sont sensibles face à la sécheresse, pouvant provoquer l’émission d’une quantité importante de carbone dans
        l'atmosphère. Bien que couvrant moins de 0,9 % de la région, ces tourbières abritent une diversité d'espèces rares. Les sols argileux, les
        argiles et les smectites se contractent lors de saisons sèches prolongées, un phénomène qui s'accentue avec l'infiltration des pluies,
        provoquant un gonflement. Ce phénomène peut affecter les infrastructures dans les zones urbaines. Le stress hydrique provoquant un
        dessèchement du couvert végétal mets les zones au risque de feux de forêts comme le précise le GIEC dans son 6ème Rapport. Pendant la période
        de 2000 à 2022, les provinces du nord de la Wallonie ont été confrontées à des sécheresses caractérisées par des bilans hydriques
        déficitaires.
      </p>
      <h4>Vulnérabilité</h4>
      <p>
        Les sols de la Région wallonne sont diversifiés. En raison de leurs propriétés physico-chimiques, texture et structure dépendent leurs
        sensibilités face aux sécheresses par leur capacité de retenir l’eau et préserver l’humidité du sol.
      </p>
      <h4>Impacts</h4>
      <p></p>
      <h4>Ce que montrent les cartes</h4>
      <p>
        Cette carte vous permet d’identifier les zones les plus à risque de sécheresse, en se basant sur les propriétés des différents types de sols
        wallons. Elle présente les potentiels d’infiltration et de rétention en eau ainsi que le taux de matière organique des sols. Les scénarios
        futurs de changement climatique et d’occupation des sols y sont associés pour vous permettre d’analyser le risque.
      </p>
      <h4>Rapport méthodologique complet</h4>
      <p>
        <a href="">[Pirlot, C. et al. 2024]</a>
      </p>
    </div>
  );
}
