import { Theme } from "shared/Theme";
import { UseQueryResult, useQuery } from "react-query";

import { BiodiversitéDemo } from "./impacts/biodiversity/Main";
import { VillesICU } from "./impacts/villes_ICU_V1/Main";
import { Air } from "./impacts/villes_airquality_V1/Main";
import { SolsSecheresse } from "./impacts/sols_secheresses_V1/Main";
import { SolsInondations } from "./impacts/sols_inondations_V1/Main";
import { SolsAgricoles } from "./impacts/sols_agricoles_V1/Main";
import { Social } from "./impacts/social_V1/Main";
import { Sante } from "./impacts/sante_V1/Main";
import { Logement } from "./impacts/logement_V1/Main";
import { Economie } from "./impacts/economie_V1/Main";
import { SecheressesHydrographiques } from "./impacts/eau_secheresses_V1/Main";
import { PollutionEau } from "./impacts/eau_pollutions_V1/Main";
import { EauInondation } from "./impacts/eau_inondations_V1/Main";
import { ClimatProjections } from "./impacts/climat_projections_V1/Main";
import { BiodiversiteSes } from "./impacts/biodiversity_SES/Main";
import { BiodiversiteResEco } from "./impacts/biodiversity_ResEco_V1/Main";
import { AgricultureElevage } from "./impacts/agriculture_elevage_V1/Main";
import { AgricultureCultures } from "./impacts/agriculture_cultures_V1/Main";
import { AgricultureAgroClim } from "./impacts/agriculture_agroclim_V1/Main";
//import {} from "./impacts/infrastructures_inondations_V1/Main";
//import {} from "./impacts/infrastructures_incendies_V1/Main";
//import {} from "./impacts/energie_V1/Main";

const data: Theme[] = [
  BiodiversitéDemo,
  BiodiversiteResEco,
  BiodiversiteSes,
  VillesICU,
  Air,
  SolsSecheresse,
  SolsInondations,
  SolsAgricoles,
  Social,
  Sante,
  Logement,
  Economie,
  SecheressesHydrographiques,
  PollutionEau,
  EauInondation,
  ClimatProjections,
  AgricultureElevage,
  AgricultureCultures,
  AgricultureAgroClim,
];

export const getThemes = async (): Promise<Theme[]> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data);
    }, 10);
  });
};

export const useThemes = (): UseQueryResult<Theme[]> => useQuery(["staticThemes"], getThemes);
