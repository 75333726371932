import Content from "./Content";

//changer "inondations" partout
import logo from "../../../assets/icons/climate.svg";
export const ClimatProjections = {
  id: "projections",
  label: "Projections",
  cluster: "climat",
  clusterLabel: "Climat",
  description: (
    <div>
      <p>
        Cette page présente les projections climatiques pour la région Wallonne selon les dernières données à disposition. Ces projections permettent
        d'explorer quel sera le climat potentiel de la Wallonie dans un monde où, à l'échelle planétaire, on aurait atteint des réchauffement de +2°C,
        +3°C ou +4°C par rapport à la période 1850-1900.
      </p>
    </div>
  ),
  //changer
  icon: logo,

  //compléter
  maps: ["", ""],
  content: <Content />,
  //supprimer non pertinent
  associatedThemes: [
    "agriculture",
    "biodiversité",
    "eau",
    "sols",
    "economie",
    "energie",
    "infrastructures",
    "logements",
    "sante",
    "social",
    "tourisme",
    "villes",
  ],
};
