import React from "react";
import { LocationBreadcrumb } from "../Utils/Location";
import { useTheme } from "../../../hooks/theme";
import { Row, Col, Button } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useMap } from "../../../hooks/map";
import { useMapCompare } from "../../../hooks/mapCompare";
import { useSidePanelStore } from "../../../hooks/sidePanel";

const SidePanelHeader = () => {
  const [map, setMap] = useMap();
  const [mapCompare, setMapCompare] = useMapCompare();
  const [theme, setTheme] = useTheme();

  return (
    <div>
      {theme && (
        <PageHeader
          onBack={() => {
            setTheme(undefined);
            setMap(undefined);
            setMapCompare(undefined);
          }}
          title={theme.label}
          breadcrumb={<LocationBreadcrumb />}
          subTitle={theme.clusterLabel}
        >
          <Row gutter={32}>
            <Col span={6}>{<img src={theme.icon} alt={theme.label} style={{ width: "100%" }} />}</Col>
            <Col span={18}>{theme.description}</Col>
          </Row>
        </PageHeader>
      )}
    </div>
  );
};

export default SidePanelHeader;
