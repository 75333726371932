import Content from "./Content";
import Adaptation from "./Adaptation";

//changer "inondations" partout
import logo from "../../../assets/icons/ville.svg";
export const VillesICU = {
  id: "icu",
  label: "ICU",
  cluster: "ville",
  clusterLabel: "Ville",
  description: (
    <div>
      <p>
        Sous l’effet du changement climatique, les vagues de chaleur se renforceront tant en fréquence qu’en durée et en intensité. Ce constat vaut
        pour la Wallonie. Or, ces vagues de chaleurs sont généralement plus intenses en ville, car elles sont amplifiées par le phénomène d’îlot de
        chaleur urbain (ICU). En effet, les constructions urbaines stockent la chaleur le jour et la restituent la nuit, tandis que leur configuration
        empêche une bonne ventilation de la ville. La forte activité humaine génère aussi des flux de chaleur. La faible présence du végétal et de
        l’eau peine à rafraîchir l’air ambiant. En conséquence, la température est plus élevée en ville qu’à la campagne, avec des impacts notamment
        sur le confort thermique et la santé des citadins.
      </p>
    </div>
  ),

  //compléter
  aleas: [{ name: "vague de chaleur" }],
  facteurs_aggravant: [
    { name: "urbanisation" },
    { name: "imperméabilisation des sols" },
    { name: "climatisation" },
    { name: "activités humaines (transport, industrie)" },
  ],
  expositions: [{ name: "Population" }],
  vulnérabilités: [
    { name: "caractéristiques socio-démographiques (âge, sexe, comorbidité)" },
    { name: "caractéristiques socio-économiques (revenu, qualité du logement" },
    { name: "Accès à un espace vert proche" },
  ],
  impacts: [
    { name: "Perte de confort thermique" },
    { name: "Hausse de la mortalité de morbidité" },
    { name: "Diminution de la productivité au travail" },
    { name: "Accroissement des inégalités" },
  ],
  content: <Content />,
  adaptation: <Adaptation />,

  //changer
  icon: logo,

  //compléter
  maps: ["", ""],

  //supprimer non pertinent
  associatedThemes: ["economie", "energie", "infrastructures", "logements", "sante", "social"],
};
