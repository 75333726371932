//import { Theme } from "shared/Theme";
import { UseQueryResult, useQuery } from "react-query";

const maps = [
  {
    id: "FichierEcologiquedesEssences",
    label: "Fichier Ecologique des Essences",
    description: `Dans le cadre du nouveau Fichier Ecologique des Essences, cette série de couches de données permet d’appréhender la bonne adéquation entre station et essence forestière.`,
    scenarios: [
      {
        label: "Actuel",
        url: "https://geoservices.wallonie.be/arcgis/services/FAUNE_FLORE/FEE/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Business as usual",
        url: "https://geoservices.wallonie.be/arcgis/services/FAUNE_FLORE/FEE/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Scénario agroécologie",
        url: "https://geoservices.wallonie.be/arcgis/services/FAUNE_FLORE/FEE/MapServer/WMSServer",
        layers: ["0"],
      },
    ],
  },
  {
    id: "masqueForestier",
    label: "Masque forestier",
    description: `Le masque forestier "2018" correspond aux étendues de forêt de plus de 20 m de largeur et 50 ares de surface (définition FAO) à l'échelle de la Wallonie.`,
    scenarios: [
      {
        label: "Actuel",
        url: "https://geoservices.wallonie.be/arcgis/services/FORET/FORET/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Business as usual",
        url: "https://geoservices.wallonie.be/arcgis/services/FORET/FORET/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Scénario agroécologie",
        url: "https://geoservices.wallonie.be/arcgis/services/FORET/FORET/MapServer/WMSServer",
        layers: ["0"],
      },
    ],
  },
  {
    id: "ReseauNatura2000",
    label: "Réseau Natura 2000",
    description:
      "Natura 2000 est un réseau écologique européen de zones protégées naturelles (tourbières, forêts, rivières, rochers, grottes), semi-naturelles (prairies fleuries, pelouses sèches, landes, etc.) ou servant d'habitat propre à certaines espèces animales ou végétales. L'objectif global de ce réseau est de maintenir la biodiversité des milieux en définissant un cadre commun pour la conservation des habitats naturels ainsi que de la faune et de la flore sauvages sur le territoire des États membres.",
    scenarios: [
      {
        label: "Actuel",
        url: "https://geoservices.wallonie.be/arcgis/services/FAUNE_FLORE/NATURA2000/MapServer/WMSServer",
        layers: ["4", "2"],
      },
      {
        label: "Business as usual",
        url: "https://geoservices.wallonie.be/arcgis/services/FAUNE_FLORE/NATURA2000/MapServer/WMSServer",
        layers: ["2"],
      },
      {
        label: "Scénario agroécologie",
        url: "https://geoservices.wallonie.be/arcgis/services/FAUNE_FLORE/NATURA2000/MapServer/WMSServer",
        layers: ["2"],
      },
    ],
  },

  {
    id: "ServicesEcosystemiques",
    label: "Services Ecosystémiques (SES)",
    description:
      "Les services écosystémiques sont la contribution des écosystèmes au bien-être humain. Ils représentent les flux partant des écosystèmes vers la société humaine.",
    scenarios: [
      {
        label: "Actuel",
        url: "https://geoservices.wallonie.be/arcgis/services/SOL_SOUS_SOL/SES/MapServer/WMSServer",
        layers: ["1"],
      },
      {
        label: "Business as usual",
        url: "https://geoservices.wallonie.be/arcgis/services/SOL_SOUS_SOL/SES/MapServer/WMSServer",
        layers: ["1"],
      },
      {
        label: "Scénario agroécologie",
        url: "https://geoservices.wallonie.be/arcgis/services/SOL_SOUS_SOL/SES/MapServer/WMSServer",
        layers: ["1"],
      },
    ],
  },
  {
    id: "EspecesExotiques",
    label: "Especes Exotiques Envahissantes (EEE)",
    description:
      "Les espèces exotiques envahissantes ou espèces invasives ont été introduites délibérément ou clandestinement en Wallonie. Elles se répandent de manière incontrôlée et menacent la biodiversité locale.",
    scenarios: [
      {
        label: "Actuel",
        url: "https://geoservices.wallonie.be/arcgis/services/FAUNE_FLORE/BERCE_CAUCASE/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Business as usual",
        url: "https://geoservices.wallonie.be/arcgis/services/FAUNE_FLORE/BERCE_CAUCASE/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Scénario agroécologie",
        url: "https://geoservices.wallonie.be/arcgis/services/FAUNE_FLORE/BERCE_CAUCASE/MapServer/WMSServer",
        layers: ["0"],
      },
    ],
  },
  {
    id: "AleaInondation",
    label: "Aléa d'inondation",
    description:
      "L'aléa d'inondation par débordement et par ruissellement reprend les zones susceptibles d’être inondées de manière plus ou moins importantes et/ou fréquentes, suite au débordement naturel d'un cours d'eau et à la concentration du ruissellement des eaux pluviales.",
    scenarios: [
      {
        label: "Actuel",
        url: "https://geoservices.wallonie.be/arcgis/services/EAU/ALEA_INOND/MapServer/WMSServer",
        layers: ["6"],
      },
      {
        label: "Business as usual",
        url: "https://geoservices.wallonie.be/arcgis/services/EAU/ALEA_INOND/MapServer/WMSServer",
        layers: ["6"],
      },
      {
        label: "Scénario agroécologie",
        url: "https://geoservices.wallonie.be/arcgis/services/EAU/ALEA_INOND/MapServer/WMSServer",
        layers: ["6"],
      },
    ],
  },
  {
    id: "BassinsVersants",
    label: "Bassins versants contributifs des secteurs PARIS",
    description:
      "Cette série de couches de données rassemble les unités de gestion homogène sur laquelle repose la mise en œuvre du Programmes d’Action(2016 - 2021) sur les Rivières par une approche Intégrée et Sectorisée (PARIS).",
    scenarios: [
      {
        label: "Actuel",
        url: "https://geoservices.wallonie.be/arcgis/services/EAU/SECTEURS_PARIS_1621/MapServer/WMSServer",
        layers: ["1", "0"],
      },
      {
        label: "Business as usual",
        url: "https://geoservices.wallonie.be/arcgis/services/EAU/SECTEURS_PARIS_1621/MapServer/WMSServer",
        layers: ["1", "0"],
      },
      {
        label: "Scénario agroécologie",
        url: "https://geoservices.wallonie.be/arcgis/services/EAU/SECTEURS_PARIS_1621/MapServer/WMSServer",
        layers: ["1", "0"],
      },
    ],
  },

  // Chapitre BIODIVERSIT2
  {
    id: "ReseauNatura2000",
    label: "Réseau Natura 2000",
    description:
      "Natura 2000 est un réseau écologique européen de zones protégées naturelles (tourbières, forêts, rivières, rochers, grottes), semi-naturelles (prairies fleuries, pelouses sèches, landes, etc.) ou servant d'habitat propre à certaines espèces animales ou végétales. L'objectif global de ce réseau est de maintenir la biodiversité des milieux en définissant un cadre commun pour la conservation des habitats naturels ainsi que de la faune et de la flore sauvages sur le territoire des États membres.",
    scenarios: [
      {
        label: "Actuel",
        url: "https://geoservices.wallonie.be/arcgis/services/FAUNE_FLORE/NATURA2000/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Business as usual",
        url: "https://geoservices.wallonie.be/arcgis/services/FAUNE_FLORE/NATURA2000/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Scénario agroécologie",
        url: "https://geoservices.wallonie.be/arcgis/services/FAUNE_FLORE/NATURA2000/MapServer/WMSServer",
        layers: ["0"],
      },
    ],
  },

  {
    id: "ServicesEcosystemiques",
    label: "Services Ecosystémiques (SES)",
    description:
      "Les services écosystémiques sont la contribution des écosystèmes au bien-être humain. Ils représentent les flux partant des écosystèmes vers la société humaine.",
    scenarios: [
      {
        label: "Actuel",
        url: "https://geoservices.wallonie.be/arcgis/services/SOL_SOUS_SOL/SES/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Business as usual",
        url: "https://geoservices.wallonie.be/arcgis/services/SOL_SOUS_SOL/SES/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Scénario agroécologie",
        url: "https://geoservices.wallonie.be/arcgis/services/SOL_SOUS_SOL/SES/MapServer/WMSServer",
        layers: ["0"],
      },
    ],
  },
  {
    id: "EspecesExotiques",
    label: "Especes Exotiques Envahissantes (EEE)",
    description:
      "Les espèces exotiques envahissantes ou espèces invasives ont été introduites délibérément ou clandestinement en Wallonie. Elles se répandent de manière incontrôlée et menacent la biodiversité locale.",
    scenarios: [
      {
        label: "Actuel",
        url: "https://geoservices.wallonie.be/arcgis/services/FAUNE_FLORE/BERCE_CAUCASE/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Business as usual",
        url: "https://geoservices.wallonie.be/arcgis/services/FAUNE_FLORE/BERCE_CAUCASE/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Scénario agroécologie",
        url: "https://geoservices.wallonie.be/arcgis/services/FAUNE_FLORE/BERCE_CAUCASE/MapServer/WMSServer",
        layers: ["0"],
      },
    ],
  },
  // Chapitre EAU - Inondations
  {
    id: "AleaInondation",
    label: "Aléa d'inondation",
    description:
      "L'aléa d'inondation par débordement et par ruissellement reprend les zones susceptibles d’être inondées de manière plus ou moins importantes et/ou fréquentes, suite au débordement naturel d'un cours d'eau et à la concentration du ruissellement des eaux pluviales.",
    scenarios: [
      {
        label: "Actuel",
        url: "https://geoservices.wallonie.be/arcgis/services/EAU/ALEA_INOND/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Business as usual",
        url: "https://geoservices.wallonie.be/arcgis/services/EAU/ALEA_INOND/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Scénario agroécologie",
        url: "https://geoservices.wallonie.be/arcgis/services/EAU/ALEA_INOND/MapServer/WMSServer",
        layers: ["0"],
      },
    ],
  },
  {
    id: "BassinsVersants",
    label: "Bassins versants contributifs des secteurs PARIS",
    description:
      "Cette série de couches de données rassemble les unités de gestion homogène sur laquelle repose la mise en œuvre du Programmes d’Action(2016 - 2021) sur les Rivières par une approche Intégrée et Sectorisée (PARIS).",
    scenarios: [
      {
        label: "Actuel",
        url: "https://geoservices.wallonie.be/arcgis/rest/services/EAU/SECTEURS_PARIS_1621/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Business as usual",
        url: "https://geoservices.wallonie.be/arcgis/rest/services/EAU/SECTEURS_PARIS_1621/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Scénario agroécologie",
        url: "https://geoservices.wallonie.be/arcgis/rest/services/EAU/SECTEURS_PARIS_1621/MapServer/WMSServer",
        layers: ["0"],
      },
    ],
  },

  // Chapitre EAU - Secheresses
  {
    id: "SolsTeneurEau",
    label: "Teneurs en eau des sols agricoles",
    description:
      "Cette série de couches de données concerne les propriétés hydrauliques des sols agricoles: conductivité hydraulique à saturation (extrapolation) et les teneurs en eau à saturation (porosité), à la capacité au champ, au point de flétrissement et pour sol séché à l’air.",
    scenarios: [
      {
        label: "Actuel",
        url: "https://geoservices.wallonie.be/arcgis/services/SOL_SOUS_SOL/SOL_EAU/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Business as usual",
        url: "https://geoservices.wallonie.be/arcgis/services/SOL_SOUS_SOL/SOL_EAU/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Scénario agroécologie",
        url: "https://geoservices.wallonie.be/arcgis/services/SOL_SOUS_SOL/SOL_EAU/MapServer/WMSServer",
        layers: ["0"],
      },
    ],
  },

  {
    id: "MESO",
    label: "Masses d'eau souterraine",
    description:
      "Cette série de couches de données concerne les propriétés hydrauliques des sols agricoles: conductivité hydraulique à saturation (extrapolation) et les teneurs en eau à saturation (porosité), à la capacité au champ, au point de flétrissement et pour sol séché à l’air.",
    scenarios: [
      {
        label: "Actuel",
        url: "https://geoservices.wallonie.be/arcgis/services/EAU/MASSES_EAU_SOUT_2017/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Business as usual",
        url: "https://geoservices.wallonie.be/arcgis/services/EAU/MASSES_EAU_SOUT_2017/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Scénario agroécologie",
        url: "https://geoservices.wallonie.be/arcgis/services/EAU/MASSES_EAU_SOUT_2017/MapServer/WMSServer",
        layers: ["0"],
      },
    ],
  },
  {
    id: "MESU",
    label: "Masses d'eau de surface",
    description:
      "Cette série de couches de données localise les masses d'eau de surface telles que des réservoirs, rivières, fleuves, canaux ou partie de ces ensembles en Wallonie",
    scenarios: [
      {
        label: "Actuel",
        url: "https://geoservices.wallonie.be/arcgis/services/EAU/MESU/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Business as usual",
        url: "https://geoservices.wallonie.be/arcgis/services/EAU/MESU/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Scénario agroécologie",
        url: "https://geoservices.wallonie.be/arcgis/services/EAU/MESU/MapServer/WMSServer",
        layers: ["0"],
      },
    ],
  },
  {
    id: "ZoneDistributionEau",
    label: "Zones de distribution en eau",
    description:
      "Unités de base du contrôle de la qualité de l'eau de distribution, gérées par des distributeurs wallons, à savoir la Société Wallonne des Eaux (SWDE), des intercommunales et des administrations ou régies communales.",
    scenarios: [
      {
        label: "Actuel",
        url: "https://geoservices.wallonie.be/arcgis/services/INDUSTRIES_SERVICES/ZDE/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Business as usual",
        url: "https://geoservices.wallonie.be/arcgis/services/INDUSTRIES_SERVICES/ZDE/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Scénario agroécologie",
        url: "https://geoservices.wallonie.be/arcgis/services/INDUSTRIES_SERVICES/ZDE/MapServer/WMSServer",
        layers: ["0"],
      },
    ],
  },
  // Chapitre EAU - pollutions

  // Chapitre SOLS
  {
    id: "Carbiosol",
    label: "Carbone organique total des Sols",
    description: "Informations relatives à la cartographie des teneurs et stocks de Carbone Organique Total (COT) des sols agricoles de Wallonie.",
    scenarios: [
      {
        label: "Actuel",
        url: "https://geoservices.wallonie.be/arcgis/services/SOL_SOUS_SOL/CARBIOSOL/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Business as usual",
        url: "https://geoservices.wallonie.be/arcgis/services/SOL_SOUS_SOL/CARBIOSOL/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Scénario agroécologie",
        url: "https://geoservices.wallonie.be/arcgis/services/SOL_SOUS_SOL/CARBIOSOL/MapServer/WMSServer",
        layers: ["0"],
      },
    ],
  },
  {
    id: "SolsHydrauliques",
    label: "Teneurs en eau des sols agricoles wallons et conductivité hydraulique proche de la saturation",
    description:
      " propriétés hydrauliques des sols agricoles de la région wallonne calculées sur base des fonctions de pédotransfert de Weynants et al. (2009).",
    scenarios: [
      {
        label: "Actuel",
        url: "https://geoservices.wallonie.be/arcgis/services/SOL_SOUS_SOL/SOL_EAU/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Business as usual",
        url: "https://geoservices.wallonie.be/arcgis/services/SOL_SOUS_SOL/SOL_EAU/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Scénario agroécologie",
        url: "https://geoservices.wallonie.be/arcgis/services/SOL_SOUS_SOL/SOL_EAU/MapServer/WMSServer",
        layers: ["0"],
      },
    ],
  },
  {
    id: "CNSW",
    label: "Carbone Numérique des Sols de Wallonie",
    description: "Compile l'ensemble des informations relatives à la Carte Numérique des Sols de Wallonie.",
    scenarios: [
      {
        label: "Actuel",
        url: "https://geoservices.wallonie.be/arcgis/services/SOL_SOUS_SOL/CNSW/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Business as usual",
        url: "https://geoservices.wallonie.be/arcgis/services/SOL_SOUS_SOL/CNSW/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Scénario agroécologie",
        url: "https://geoservices.wallonie.be/arcgis/services/SOL_SOUS_SOL/CNSW/MapServer/WMSServer",
        layers: ["0"],
      },
    ],
  },
  {
    id: "SolsErosion",
    label: "Carte de la Sensibilité des sols à l’érosion ",
    description: "L’érosion potentielle des sols sur l’ensemble du territoire wallon selon des mailles de 10*10m",
    scenarios: [
      {
        label: "Actuel",
        url: "https://geoservices.wallonie.be/arcgis/services/SOL_SOUS_SOL/EPS/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Business as usual",
        url: "https://geoservices.wallonie.be/arcgis/services/SOL_SOUS_SOL/EPS/MapServer/WMSServer",
        layers: ["0"],
      },
      {
        label: "Scénario agroécologie",
        url: "https://geoservices.wallonie.be/arcgis/services/SOL_SOUS_SOL/EPS/MapServer/WMSServer",
        layers: ["0"],
      },
    ],
  },
  {
    id: "Air",
    label: "Indice de Qualité de l'air BelAQI",
    description: "Cet indice rassemble en une seule valeur représentative les concentrations de plusieurs polluants atmosphériques.",
    scenarios: [
      {
        label: "Actuel",
        url: "https://geo.irceline.be/rioifdm/ows?service=WMS&version=1.3.0&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=AQindex_2013_10m_VLA",
        layers: ["0"],
      },
      {
        label: "Business as usual",
        url: "https://geo.irceline.be/rioifdm/ows?service=WMS&version=1.3.0&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=AQindex_2013_10m_VLA",
        layers: ["0"],
      },
      {
        label: "Scénario agroécologie",
        url: "https://geo.irceline.be/rioifdm/ows?service=WMS&version=1.3.0&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=AQindex_2013_10m_VLA",
        layers: ["0"],
      },
    ],
  },
];

export const getMaps = async (): Promise<typeof maps> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(maps);
    }, 10);
  });
};

export const useMaps = () => useQuery(["maps"], getMaps);
