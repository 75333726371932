import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";
import { useLocation } from "../../../components/Thematic/Utils/Location";

export default function Content() {
  //@ts-ignore
  const [event, setEvent] = useContext(EventContext);
  const [location, setLocation] = useLocation();

  return (
    <div>
      <h4>Aléa</h4>
      <p>
        La perte de biodiversité et la dégradation des habitats naturels ont un effet non négligeable sur les écosystèmes, notamment sur leur capacité
        de résilience et d’atténuation des changements climatiques, et celle à fournir des services écosystémiques tels que le stockage de carbone
        dans le sol, la pollinisation, la valeur récréative et sanitaire, la régulation des cycles hydrogéochimiques terrestres, un effet d’îlot de
        fraîcheur lors de forte chaleur, … Par ailleurs, les zones urbaines et agricoles peuvent abriter une biodiversité importante et agir comme
        corridors au sein du réseau écologique.
      </p>
      <h4>Exposition</h4>
      <p>
        La dégradation des services rendus par la nature pour l’homme a des répercussions directes sur de nombreux secteurs d’activité. Un déclin de
        la pollinisation peut entraîner une chute des rendements agricoles, tandis que la perte de biodiversité peut augmenter la vulnérabilité des
        cultures aux ravageurs, aux maladies et aux sécheresses. La perte de résilience des écosystèmes forestiers peut accroître les risques de feux
        de forêt et diminuer la production de bois, ce qui a un impact sur les secteurs énergétiques et de la construction. Le dérèglement des cycles
        hydrologiques peut affecter les débits des cours d’eau et la qualité de l’eau, impactant la production d’énergie hydroélectrique ainsi que les
        processus industriels gourmands en eau. Ceci peut également entraîner des conséquences drastiques sur les populations de poissons et sur de la
        flore aquatique, affectant à son tour le secteur de la pêche. Enfin, la diminution de la qualité récréative et sanitaire des écosystèmes peut
        entrainer des modifications des activités de tourisme dans une région donnée, affectant ses activités économiques, et une augmentation des
        maladies liées à la qualité de l’eau, de l’air et du sol, entraînant des coûts sociétaux importants.
      </p>
      <h4>Vulnérabilité</h4>
      <p>
        Une adaptation anticipative des secteurs d’activité à leurs conditions environnementales permettra de faire face aux modifications des
        services écosystémiques de façon plus stable. La mise en place de mesures agroécologiques, la réorganisation des activités touristiques d’une
        région, repenser nos façons de construire et de produire, … sont tous des éléments qui permettront de diminuer la vulnérabilité des secteurs
        économiques impactés.
      </p>
      <h4>Facteurs aggravants</h4>
      <p>
        Ces divers aléas ont non seulement un effet sur toute une série de secteurs d’activités économiques, mais aussi une rétroaction importante sur
        les facteurs extérieurs à la source de cette perte de résilience du système et de la dégradation des services écosystémiques (fragmentation
        des habitats, modification des interactions entre espèces et des aires de distribution des espèces, …).
      </p>
      <h4>Impacts</h4>
      <p>
        Plusieurs études, dont "l'évaluation de l'impact socio-économique du changement climatique en Belgique{" "}
        <a href="https://climat.be/actualites/2020/impact-socio-economique-en-belgique">[CNC 2020]</a> essayent de chiffrer les pertes économiques
        liés à la baisse des services écosystémiques dans un climat futur.
      </p>

      <h4>Ce que montrent les cartes</h4>
      <p>
        Sur ces cartes vous pouvez explorer la vulnérabilité des milieux naturels wallons. Les scénarios futurs de changements climatiques et
        d'occupation du sol peuvent être sélectionnés en haut de la carte.
      </p>
      <p>
        <b>Le taux de réduction du potentiel à fournir le service écosystémique.</b>
        La partie gauche de la carte montre le potentiel actuel de fournir du service écosystémique. La partie droite représente son augmentation ou
        sa diminution selon les scénarios climatiques et d'occupation du sol choisis pour le futur. Les zones les plus à risque de perte du potentiel
        du service écosystémique sont représentées en rouge. Les services écosystémiques pertinents à explorer dans le cadre de l'adaptation aux
        changements climatiques sont :
        <ul>
          <li>production alimentaire</li>
          <li>production de bois</li>
          <li>production pharmaceutique ??</li>
          <li>production de biomasse pour l'énergie</li>
          <li>régulation du cycle de l'eau</li>
          <li>protection contre l'érosion</li>
          <li>protection contre les inondations</li>
          <li>protection contre les tempêtes</li>
          <li>prévention et contrôle des feux</li>
          <li>régulation des processus biologiques</li>
          <li>séquestration carbone</li>
          <li>régulation du climat (régional et local)</li>
          <li>support culuturel à la vie courante (santé, bien-être)</li>
          <li>support aux loisirs</li>
        </ul>
      </p>

      <h4>Rapport méthodologique complet</h4>
      <p>
        <a href="">[Fanal, A. et al. 2024]</a>
      </p>
    </div>
  );
}
