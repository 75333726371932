import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";

export default function Methodologie() {
  //const [event, setEvent] = useContext(EventContext);

  return (
//Décrire les mesures d'adaptation possible en lien avec les vulnérabilités explorées sur les cartes
    <div>
     <h4>Que faire ?</h4>
      <p>
      Le facteur aggravant majeur en matière d'inondations est l'imperméabilisation des terres dans le bassin versant.
      Aujourd'hui la plupart des gens sont bien conscients qu'il ne faut plus construire dans les zones à risque d'inondation et en particulier dans les zones d'aléa élevé.
      Il est nécessaire a présent de bien appréhender et visualiser le lien qui existe entre l'aléa d'inondation dans la plaine en aval et les constructions qui se font sur les hauteurs, dans le bassin versant contributif.
      Il est tout aussi important d'arrêter de construire dans ces zones si cela aggrave les risques d'inondations en aval. 
      <a href="">[(Voir fiche mesure XX)]</a>
      </p>
      <p>
      Après avoir tout mis en oeuvre pour ne pas aggraver la situation, une piste d’adaptation, utile tant face aux crues qu’en prévision de sécheresses est de freiner le ruissellement des eaux au sein des bassins versants et de favoriser l’infiltration de l’eau dans les sols puis vers les nappes. 
      Afin de favoriser l’infiltration de l’eau, des aménagements du paysage au sein des bassins versants peuvent être mis en place comme les noues, les fossés, les puits d’infiltration, les zones humides ou encore des haies. 
      Ces zones peuvent également servir de zones de développement de biodiversité. 
      <a href="">[(Voir fiche mesure XX)]</a>
     </p>
      <p>
      Dans les zones fort artificialisées, comme en ville, l’utilisation de matériaux de construction plus perméable et la mise en place d’infrastructure végétalisée et de zones perméables comme des jardins de pluies ou des bassins d’infiltration sont à prioriser. 
      Les dispositifs d’infiltration ou de temporisation des eaux peuvent être intégrés aux infrastructures à construire ou existantes et générer des co-bénéfices pour la biodiversité, la fraicheur et l’esthétique. 
      La collecte et le stockage de l’eau de pluie en vue de sa réutilisation locale est une solution à privilégier. 
      <a href="">[(Voir fiche mesure XX)]</a>
      </p>  
    </div>
  );
}
