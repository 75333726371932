import Content from "./Content";
import Adaptation from "./Adaptation";

import logo from "../../../assets/icons/agriculture.svg";
export const AgricultureElevage = {
  id: "rendements_elevage",
  label: "Rendements d'Elevage",
  cluster: "agriculture",
  clusterLabel: "Agriculture",
  description: (
    <div>
      <p>
        L'augmentation des vagues de chaleur confronte les animaux d’élevage à des stress thermiques plus important et plus fréquents. Le stress
        thermique survient lors des périodes de fortes chaleurs et d’humidité relative élevée, durant lesquelles les animaux n’arrivent plus à réguler
        la température de leurs corps. Cela a des effets négatifs sur la santé des animaux et sur le contrôle des épidémies. Pour l'exploitation
        agricole cela signifie des baisses de rendements en lait et en viande. Par ailleurs les impacts du dérèglement climatique sur les cultures{" "}
        <a href="">(voir page "cultures")</a> affecteront également les prairies et cultures fourragères ce qui impactera la disponibilité des
        aliments pour l'élevage.
      </p>
    </div>
  ),

  //compléter
  aleas: [{ name: "vagues de chaleurs" }, { name: "humidité élevée" }, { name: "rayonnement solaire élevé" }],
  facteurs_aggravant: [{ name: "" }],
  expositions: [{ name: "prairies" }, { name: "bâtiments d’élevage" }],
  vulnérabilités: [{ name: "ombrage" }, { name: "matériaux des bâtiments" }, { name: "variété animale" }],
  impacts: [{ name: "maladies animales" }, { name: "diminution du bien-être animal" }, { name: "baisse de rendement" }],
  content: <Content />,
  adaptation: <Adaptation />,

  //changer
  icon: logo,

  //compléter
  maps: [],

  //supprimer non pertinent
  associatedThemes: ["biodiversité", "sols", "eau"],
};
