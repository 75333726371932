//supprimer selon pertinence
import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";
import { useLocation } from "../../../components/Thematic/Utils/Location";
export default function Content() {
  //@ts-ignore
  const [event, setEvent] = useContext(EventContext);
  const [location, setLocation] = useLocation();

  return (
    <div>
      <h4>Aléa</h4>
      <p>
        Le dérèglement climatique entraine une augmentation de la fréquence des évènements météorologiques extrêmes, notamment les vagues de chaleurs
        estivales, dont les canicules. Des températures qui n’étaient alors atteintes qu’une fois tous les 10 ans, le seront 4 fois sur la même
        période (GIEC). Au niveau des précipitations, les épisodes de pluies extrêmes, comme celui connu en 2021 deviendront plus courants. La période
        de retour de 50 ans connue jusqu’à présent passe à 10 ans dans le scenario +2°C. De plus, le nombre de jours de précipitations diminue en été.
        En effet, même si ce nombre de jours ne diminue pas à l’échelle du siècle jusqu’à présent, le risque que la tendance se confirme n’est pas
        pour autant exclu, comme l’ont montré les étés secs entre 2017 et 2019. La probabilité d’occurrence des gels tardifs au printemps (durant la
        période de bourgeonnement entre autres) et des gels hâtifs en automne augmente de 40 à 60% selon les scénarios (World Weather Attribution).
        Enfin, une tendance à l’augmentation de l’ensoleillement est observée. Cependant, il est pour l’heure difficile de dire si ces changements
        sont liés à la variabilité naturelle du climat ou au dérèglement climatique (Uliège).
      </p>
      <h4>Facteurs aggravants</h4>
      <p>
        Différents facteurs entrent en jeu dans la sensibilité des indices agro-climatiques au dérèglement climatique, à savoir la disponibilité de
        l’eau, la dégradation des sols ainsi que l’imperméabilisation des sols. En effet, la disponibilité de l’ eau limite la possibilité
        d’irrigation supplémentaire des terres durant les fortes chaleurs et les sècheresses, aggravant l’état du bilan hydrique. La dégradation des
        sols et leur imperméabilisation augmentent le ruissellement de surface de l’eau plutôt que son infiltration – augmentant ainsi les risques
        d’inondation par ruissellement des parcelles agricoles lors des épisodes de pluies intenses.
      </p>
      <h4>Exposition</h4>
      <p>
        L'élément exposé à ces changements de paramètre agro-climatiques, ce sont les exploitations agricoles dans leur ensemble. En fonction des
        caractéristiques de l'exploitation (taille, familiale ou non, mécanisée, système d'irrigation, etc...) elle ne sera pas exposée de la même
        manière.
      </p>
      <h4>Vulnérabilité</h4>
      <p>
        Certaines pratiques agricoles rendent les cultures plus ou moins résilientes au dérèglement climatique et à ses conséquences météorologiques.
        La monoculture par exemple, soit le fait qu’un agriculteur dédie son champ à un seul type de culture à la fois, en opposition à la
        polyculture, complique la gestion de l’humidité des sols et la résilience aux épisodes météorologiques extrêmes. Lorsqu’un champ n’est couvert
        que d’une espèce de plante (monoculture), le sol ne connait qu’un seul système racinaire en charge de retenir l’humidité et prévenir l’érosion
        du sol, à la place d’un système complexe de racines différentes aux rôles multiples. La monoculture accroit donc la dégradation des sols et de
        leur capacité à absorber l’eau, augmentant les risques d’inondation par ruissellement et de sècheresse édaphique pendant les vagues de chaleur
        et période de faibles précipitations. L’absence de haies et de dispositifs de rétention d’eau encourage aussi ces phénomènes, perturbant alors
        le bilan hydrique. À ces monocultures sont souvent associées des pratiques agricoles offensives pour les sols ; tassement, labour profond,
        semis de précision, etc. qui accentuent la vulnérabilité des exploitations agricoles. De plus, les systèmes de monocultures rendent les
        agriculteurs plus sensibles aux calamités. En effet, puisque leur exploitation se concentre sur un type de culture, si un épisode
        météorologique extrême devait se produire et que le type de culture choisi y est sensible, c’est l’entièreté de la production qui est
        potentiellement perdue, renforçant alors la précarité des agriculteurs.
      </p>
      <h4>Impacts</h4>
      <p>
        La combinaison des différents éléments d'aléa, d'exposition et de vulnérabilité peuvent impacter sérieusement la viabilité économique d'une
        exploitation agricole. Au travers notamment : De l'augmentation des besoins et du coût d'irrigation en raison d'un bilan hydrique dégradé
        (disponibilité en eau dans le sol, pertes par évaporation et lessivage, pluviosité aléatoire).
      </p>
      <h4>Ce que montrent les cartes</h4>
      <p></p>
    </div>
  );
}
