//supprimer selon pertinence
import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";
import { useLocation } from "../../../components/Thematic/Utils/Location";
export default function Content() {
  //@ts-ignore
  const [event, setEvent] = useContext(EventContext);
  const [location, setLocation] = useLocation();

  return (
    <div>
      <h4>Aléa</h4>
      <p>
        Le dérèglement climatique entrainera une augmentation de la fréquence des évènements météorologiques extrêmes. Le nombre de jours où la
        température atteint plus de 25°C pourrait doubler, voire tripler d’ici la fin du siècle. En ce qui concerne le rendement des prairies, c’est
        la question des sécheresses qui est primordiale. La fréquence de celle-ci devrait doubler d’ici la fin du siècle.
      </p>
      <h4>Facteurs aggravants</h4>
      <p>
        Le dérèglement climatique et son impact sur la météo affecte les conséquences, la propagation et la prévisibilité des maladies animales (FAO).
        Les maladies à transmission vectorielle (par les tiques et les moustiques) se verront favorisées, ainsi que les maladies transmises par les
        flux hydriques ou l’alimentation dues à la prolifération de bactéries ou de virus (GIEC). L’élevage intensif et le stress thermique offrent un
        terrain propice à la prolifération de ces indésirables. En effet, les animaux d’élevage, soit leurs hôtes, sont en grand nombre en élevage
        intensif – facilitant l’alimentation et la reproduction des indésirables. Et le stress thermique rend les animaux d’élevage plus sensibles au
        niveau physiologiques et immunitaire face à ces offensives.
      </p>
      <h4>Exposition</h4>
      <p>
        Les animaux d’élevage sont soumis à deux types d’exposition :
        <ul>
          <li>Dans la pâture. L’ensoleillement et le vent influencent dans ce cas la température ressentie par les animaux.</li>
          <li>
            Dans les bâtiments d’élevage. Dans ce cas le type de matériaux de construction et les systèmes techniques de ventilation vont fortement
            influencer l’inconfort subit.
          </li>
        </ul>
      </p>
      <h4>Vulnérabilité</h4>
      <p>
        Dans les pâturages, la présence de zones d’ombrage va réduire considérablement la vulnérabilité des animaux exposés. En effet, il peut y avoir
        jusqu’à 10°C de différence entre les zones d’ombre et les zones en plein soleil. Dans les bâtiments d’élevage c’est l’ombre portée sur le
        bâtiment, le type de matériaux (revêtements clairs de préférence) et l’inertie thermique de ces matériaux qui vont influencer la
        vulnérabilité. Chaque variété animale possède également une sensibilité différente à la chaleur.au niveau physiologique et immunitaire.
      </p>
      <h4>Impacts</h4>
      <p>
        La combinaison de ces facteurs peut aboutir à des conséquences telles que :
        <ul>
          <li>
            une augmentation de l’incidence et de la propagation des maladies animales, ainsi qu’une diminution de leur prévisibilité, une
            augmentation de la morbidité et de la mortalité des animaux d’élevage ;{" "}
          </li>
          <li>
            une diminution du bien-être animal en général, et donc une diminution de la qualité des produits animaux et de la sécurité alimentaire.
          </li>
        </ul>
        Ceci impliquerait une baisse des rendements et de la productivité des élevages bovins et porcins.
      </p>
      <h4>Ce que montrent les cartes</h4>
      <p>
        Sur cette carte vous pouvez explorer la vulnérabilité des élevages wallons au stress thermique. Les scénarios futurs de changements
        climatiques peuvent être sélectionnés en haut de la carte. La partie gauche de la carte montre la situation actuelle. La partie droite
        représente le stress futur estimé. Les zones les plus à risque de perte de rendement en Wallonie sont représentées en rouge.
      </p>
      <h4>Rapport méthodologique complet</h4>
      <p>
        <a href="">[, . et al. 2024]</a>
      </p>
    </div>
  );
}
