import React, { useState, createContext, useContext, useMemo, useCallback } from "react";
import { useTheme } from "../hooks/theme";
import { useMap as useThemeMap } from "../hooks/map";
import { useMapCompare as useThemeMapCompare } from "../hooks/mapCompare";
import { useScenario } from "../hooks/scenario";
import { useScenarioCompare } from "../hooks/scenarioCompare";
import { useLocation, flyToLocation, findCurrentMapLocation } from "../components/Thematic/Utils/Location";
import { useMap } from "react-map-gl";
import { Waypoint } from "react-waypoint";
import { StringParam, useQueryParam } from "use-query-params";
import { getSearchCoordinates } from "../components/Thematic/Utils/SearchBox";
import mapboxgl from "mapbox-gl";

const events = {
  bioDiversité: {
    map: "masqueForestier",
    scenario: "Business as usual",
    location: "Amay",
  },
  test2: {
    map: "masqueForestier",
    scenario: "Business as usual",
    location: "Quévy",
  },
  test3: {
    map: "masqueForestier",
    scenario: "Business as usual",
    mapCompare: "masqueForestier",
    scenarioCompare: "Business as usual",
    location: "Charleroi",
  },
};

export const EventContext = createContext<readonly [keyof typeof events | null, (eventId: keyof typeof events | null) => void]>([null, () => {}]);

export const Event: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [event, setEvent] = useState<keyof typeof events | null>(null);
  const [, setThemeMap] = useThemeMap();
  const [, setScenario] = useScenario();
  const [, setThemeMapCompare] = useThemeMapCompare();
  const [, setScenarioCompare] = useScenarioCompare();
  const flyTo = useFlyTo();

  const triggerEvent = useCallback(
    (eventId: keyof typeof events | null) => {
      if (eventId && events[eventId]) {
        if (events[eventId].map) setThemeMap(events[eventId].map);
        if (events[eventId].scenario) setScenario(events[eventId].scenario);
        setThemeMapCompare(events[eventId]["mapCompare"] || undefined);
        setScenarioCompare(events[eventId]["scenarioCompare"] || undefined);
        if (events[eventId].location) {
          flyTo(events[eventId].location, "if search, go to search municipality instead");
        }
      }
      setEvent(eventId);
    },
    [setThemeMap, setScenario, setThemeMapCompare, setScenarioCompare, flyTo],
  );
  const providerValue = useMemo(() => [event, triggerEvent] as const, [event, triggerEvent]);
  return <EventContext.Provider value={providerValue}>{children}</EventContext.Provider>;
};

export const useFlyTo = () => {
  const mapRef = useMap();
  const [currentLocation, setLocation] = useLocation();
  const [search] = useQueryParam("search", StringParam);
  return useCallback(
    async (
      location: string,
      mode:
        | "if search, force going to location"
        | "if search, go to search municipality instead"
        | "if search, do nothing" = "if search, go to search municipality instead",
    ) => {
      if (search && mode === "if search, do nothing") return;
      if (search && mode === "if search, go to search municipality instead") {
        const newCoord = await getSearchCoordinates(search);
        const searchMunicipality = newCoord && findCurrentMapLocation(new mapboxgl.LngLat(newCoord.longitude, newCoord.latitude), 13);
        if (currentLocation === searchMunicipality) return;
        if (searchMunicipality) location = searchMunicipality;
        else return;
      }
      setLocation(location);
      flyToLocation(mapRef.current?.getMap()!, location);
    },
    [mapRef, setLocation, search, currentLocation],
  );
};

export const EventHighlight: React.FC<{
  eventId: keyof typeof events;
  children: React.ReactNode;
}> = ({ eventId, children }) => {
  const [event, setEvent] = useContext(EventContext);
  return (
    <Waypoint fireOnRapidScroll={false} onEnter={() => setEvent(eventId)} topOffset="49%" bottomOffset="49%">
      <div
        style={
          event === eventId
            ? { borderLeftStyle: "solid", borderLeftColor: "#e1110f", borderLeftWidth: "6px", paddingLeft: "8px", marginLeft: -14 }
            : {}
        }
      >
        {children}
      </div>
    </Waypoint>
  );
};
