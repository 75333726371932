import Content from "./Content";
import Adaptation from "./Adaptation";

import logo from "../../../assets/icons/agriculture.svg";
export const AgricultureAgroClim = {
  id: "agriculture",
  label: "AgroClim",
  cluster: "agriculture",
  clusterLabel: "Agriculture",
  description: (
    <div>
      <p>
        L’agriculture est non seulement une source importante d'émissions de gaz à effet de serre suscitant les changements climatiques, mais aussi,
        fortement impactées par les conséquences. Pour apprécier dans quelle mesure les changements climatiques ont un impact sur la production
        agricole, différents indices peuvent être utilisés. Ces indicateurs agro-climatiques se déclinent en trois groupes principaux :
        <ul>
          <li>Indices relatifs au bilan hydrique (cumul des précipitations, sécheresse, etc.) </li>
          <li>Indices thermiques (degrés-jours de croissance, longueur saison de croissance, etc.) </li>
          <li>Indice d’ensoleillement (cumul des radiations solaires) </li>
        </ul>
        Ces indicateurs permettent aux agriculteurs d’établir des stratégies de culture à long terme afin de réduire les risques.
      </p>
    </div>
  ),

  //compléter
  aleas: [
    { name: "vagues de chaleur" },
    { name: "précipitations importantes" },
    { name: "sècheresses prolongées" },
    { name: "gèle tardif et hâtif" },
    { name: "augmentation de l’ensoleillement" },
  ],
  facteurs_aggravant: [{ name: "disponibilité en eau" }, { name: "dégradation des sols" }, { name: "imperméabilisation des sols" }],
  expositions: [{ name: "exploitations agricoles" }],
  vulnérabilités: [
    { name: "pratiques culturales" },
    { name: "potentiel de rétention de l’eau du sol" },
    { name: "sensibilité des variétés en cultures aux aléas climatiques" },
    { name: "résilience des exploitations" },
  ],
  impacts: [
    { name: "diminution de la prévisibilité des indices agro-climatiques" },
    { name: "augmentation des risques d’inondation par ruissellement et de sècheresse édaphique" },
    { name: "augmentation du risque de calamités agricoles" },
    { name: "perturbation des périodes de croissance et de la photosynthèse" },
    { name: "risques de diminution des rendements" },
    { name: "renforcement de la précarité des agriculteurs" },
  ],
  content: <Content />,
  adaptation: <Adaptation />,

  //changer
  icon: logo,

  //compléter
  maps: [],

  //supprimer non pertinent
  associatedThemes: ["biodiversité", "sols", "eau"],
};
