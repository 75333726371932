import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";

export default function Methodologie() {
  //const [event, setEvent] = useContext(EventContext);

  return (
//Décrire les mesures d'adaptation possible en lien avec les vulnérabilités explorées sur les cartes
    <div>
      <p>
      <a href="">[(Voir fiche mesure XX)]</a>
      Le projet <a href="https://awa.agriadapt.eu/fr/adaptations">AgriADAPT</a> propose des fiches adaptation pour l'agriculture européenne. 
      On peut lister par exemple, la modification des dates de semis et de la variété de culture semée, la sélection végétale, guidée par la modélisation sur le plus long terme, le choix de cultiver d'autres plantes mieux adaptées aux nouvelles conditions agro-climatiques (ex. soja ou tournesol déjà proposés plus haut) qui sera toutefois tributaire du développement des filières de valorisation sur notre territoire. 
      Parmi les mesures, certaines permettent de réagir à court terme aux dangers immédiats posés par le changement climatique (ex. équipement de refroidissement des bâtiments d’élevage par ex.) sans apporter de solution à long terme, voire en renforçant le problème climatique par les émissions de GES induites par la consommation d’énergie directe et indirecte de ces solutions. 
      D’autres, comme par ex. le recours à l’irrigation des grandes cultures, semblent même fort peu souhaitables a priori sur le long terme pour la Wallonie en raison des coûts élevés de déploiement des infrastructures ainsi que des risques que cela pose sur la ressource hydrique et les conflits d’usage qui en découleront.  
      Certaines mesures, comme l’adaptation des pratiques de pâturage peuvent être actionnées à court terme et sont des mesures sans regret car, dans l’exemple cité, elles permettent un gain dans la valorisation de l’herbe par les animaux même en l’absence de changement climatique. 
      Enfin, le recourt à l’agroforesterie ne peut s’envisager que sur le long terme, mais en tant que solution basée sur la nature, elle peut apporter, en plus d’une mitigation des risques d’érosion, des bénéfices de supports à la biodiversité et de piégeage de carbone dans les sols (et donc d’atténuation des changements climatiques), en particulier des cultures.
      <a href="">[(Voir fiche mesure XX)]</a>
      [A préciser pour les cultures ...]
      </p>
     
    </div>
  );
}
