import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";

export default function Methodologie() {
  //const [event, setEvent] = useContext(EventContext);

  return (
    <div>
      <p>Les données nécessaires à ces approches sont les suivantes :</p>
      <ul>
        <li>Données du projet « Réseau écologique wallon » : modèles des VNP, SDM d’espèces, cartographie du réseau. </li>
        <li>Modélisations d’espèces exotiques envahissantes du projet TrIAS</li>
        <li>Données climatiques </li>
        <li>Parcellaire agricole anonyme (2022) – Service Public de Wallonie </li>
        <li>Données du projet « Réseau écologique wallon » : cartographie du réseau, écotopes.</li>
        <li>Parcellaire agricole anonyme (2022) – Service Public de Wallonie </li>
        <li>Cartographie des SES et Matrice des SES. </li>
        <li>Données climatiques </li>
        <li>Scénarios de changements d’occupation du sol (Thématique « Aménagement du territoire – politique foncière »)</li>
      </ul>
      <p>
        La plupart des données nécessaires sont disponibles publiquement ou au sein de l’équipe Biodiversité et Paysage ULiège. Les données du réseau
        écologique seront rendues publiques début 2024. Cependant, une série d’indicateurs climatiques mis à jour en fonction de différents scénarios
        est nécessaire et pourrait être obtenue auprès du département climatologie de l’ULiège, dans le cas où le jeu de donnée n’est plus à jour sur
        les derniers scénarios climatiques ou ne correspond pas aux scénarios sélectionnés pour l’étude (échelle 1x1 km)1 :
      </p>
      <ul>
        <li>Annual Mean Temperature (°C) </li>
        <li>Annual Amount of Precipitation (mm year-1) </li>
        <li>Annual Variation of Precipitation (coefficient of variation) </li>
        <li>Annual Variation of Temperature (stdev) </li>
        <li>Maximum Temperature of the Warmest Month (°C) </li>
        <li>Minimum Temperature of the Coldest Month (°C) </li>
        <li>Temperature Annual Range (°C) </li>
        <li>Precipitation of the Wettest Month (mm) </li>
        <li>Precipitation of the Driest Month (mm) </li>
        <li>30 yr Mean Annual Cumulated GDD Above 5 degrees C (°C days) </li>
        <li>Annual Mean Potential Evapotranspiration (mm day-1)</li>
        <li>Annual Mean Solar Radiation (W m-2)</li>
        <li>Annual Variation of Solar Radiation (stdev)</li>
      </ul>
      <p>
        Pour les avis d’experts, différents groupes de travail seront établis. Ceux-ci impliqueront d’autres partenaires internes du consortium, ainsi
        que le SPW DEMNA, des associations naturalistes, la Belgian Biodiversity Platform, l’Observatoire Wallon pour la Santé des Forêts, LifeWatch,
        etc.
      </p>
    </div>
  );
}
