//supprimer selon pertinence
import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";
import { useLocation } from "../../../components/Thematic/Utils/Location";
export default function Content() {
  //@ts-ignore
  const [event, setEvent] = useContext(EventContext);
  const [location, setLocation] = useLocation();

  return (
    <div>
      <h4>Aléas</h4>
      <p>
        L’impact du dérèglement climatique sur les bâtiments wallons accueillant des personnes sensibles est étudié au regard de trois stress
        climatiques :
        <ul>
          <li>les vagues de chaleur;</li>
          <li>les inondations;</li>
          <li>les feux de forêts.</li>
        </ul>
        Voir respectivement les pages <a href="">Projections</a> et <a href="">Inondations</a> en ce qui concerne les projections climatiques et les
        facteurs aggravants de ces phénomènes.
      </p>

      <h4>Exposition</h4>
      <p>Nous explorons ici l'exposition des bâtiments accueillant des personnes âgées, en bas âge ou en mauvaise santé en Wallonie.</p>
      <h4>Vulnérabilité</h4>
      <p>
        Ces bâtiments sont considérés particulièrement vulnérables en raison des capacités de réaction moindre du public qu'ils accueillent et de leur
        santé plus fragile.
      </p>
      <h4>Impacts</h4>
      <p>
        Les inondations et feux de forêts peuvent engendrer des dégâts importants aux infrastructures et être mortels. Les vagues de chaleur sont
        moins extrêmes mais leur fréquence récurrente peut également avoir des impacts important sur la santé.
      </p>
      <h4>Ce que montrent les cartes</h4>
      <p>
        Sur cette carte vous pouvez identifier les bâtiments sensibles menacés par des aléas climatiques. Les scénarios futurs de changements
        climatiques peuvent être sélectionnés en haut de la carte. Il vous faut également sélectionner ci-dessous l'aléa que vous souhaitez explorer :
        <ul>
          <li>Vagues de chaleur</li>
          <li>Inondations</li>
          <li>Incendies</li>
        </ul>
        Les bâtiments à risque sont représentées en rouge.
      </p>
      <h4>Rapport méthodologique complet</h4>
      <p>
        <a href="">[Habran, S. et al. 2024]</a>
      </p>
    </div>
  );
}
