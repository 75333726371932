import React, { useState, useEffect } from "react";
import { AutoComplete, Input } from "antd";
import { geojsonMunicipalities, geojsonProvinces } from "../../../services/geojson";
import _, { set } from "lodash";
import { useLocation, flyToLocation, flyToCoordinate, findLocationGeojsonFeature, extractLocationScale, LocationScale } from "../Utils/Location";
import { useMap } from "react-map-gl";
import { getMapboxLocations, useMapboxSearch } from "../../../hooks/MapboxSearch";
import { StringParam, useQueryParam } from "use-query-params";
import bbox from "@turf/bbox";

const communes = geojsonMunicipalities?.map((f) => ({
  name: f.properties.name_fr,
  province: f.properties.prov_fr,
  population: f.properties.population,
}));
const provinces = geojsonProvinces?.map((f) => ({
  name: f.properties.name_fr,
}));

export async function getSearchCoordinates(search: string | null | undefined) {
  if (!search) return null;
  const area = extractLocationScale(search);
  if (area !== LocationScale.ROOT) {
    const feature = findLocationGeojsonFeature(search);
    console.log(feature);
    if (feature) {
      const bbox_ = bbox(feature);
      const [lng, lat] = [(bbox_[0] + bbox_[2]) / 2, (bbox_[1] + bbox_[3]) / 2];
      return { longitude: lng, latitude: lat };
    }
  }
  const mapboxResults = await getMapboxLocations(search);
  const firstResult = mapboxResults?.features?.[0];
  if (!firstResult) return null;
  const [lng, lat]: [number, number] = firstResult["center"];
  return { longitude: lng, latitude: lat };
}

const SearchBox = () => {
  //const mapboxAccessToken = {process.env.REACT_APP_MAPBOX_TOKEN!}
  const [options, setOptions] = useState<
    Array<{
      label: React.JSX.Element;
      options: Array<{ value: unknown; label: React.JSX.Element }>;
    }>
  >([]);
  const [location, setLocation] = useLocation();
  const mapRef = useMap();
  const map = mapRef.current?.getMap()!;
  const [search, setSearch] = useQueryParam("search", StringParam);
  const [searchText, setSearchText] = useState<string | null>(search || null);
  useEffect(() => {
    search && setSearchText(search);
  }, [search, setSearchText]);

  const { data: mapboxResults, isLoading } = useMapboxSearch(searchText);

  const renderTitle = (title: string) => (
    <span>
      {title}
      {/* <a style={{ float: "right" }} href="https://www.google.com/search?q=antd" target="_blank" rel="noopener noreferrer">
        more
      </a> */}
    </span>
  );

  useEffect(() => {
    if (!searchText) {
      setOptions([]);
    } else {
      const results = [
        {
          label: renderTitle("Communes"),
          options: _(communes)
            .filter((c) => c.name.toLocaleLowerCase().includes(searchText?.toLocaleLowerCase()))
            .orderBy("population", "desc")
            .take(5)
            .map((commune) => ({
              value: commune.name,
              label: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {commune.name}
                  <span>
                    {/* <UserOutlined />  */}
                    {commune.province}
                  </span>
                </div>
              ),
            }))
            .value(),
        },
        {
          label: renderTitle("Province"),
          options: _(provinces)
            .filter((p) => p.name.toLocaleLowerCase().includes(searchText?.toLocaleLowerCase()))
            .orderBy("name", "asc")
            .map((province) => ({
              value: province.name,
              label: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {province.name}
                </div>
              ),
            }))
            .value(),
        },
        {
          label: renderTitle("Lieux d'intérets"),
          options: mapboxResults
            ? mapboxResults?.features?.map((res) => ({
                value: res["place_name_fr"],
                label: (
                  <div>
                    <div>{res["text_fr"]}</div>
                    <div>
                      <small>{res["place_name_fr"].split(", ").slice(1).join(", ")}</small>
                    </div>
                  </div>
                ),
              }))
            : [],
        },
      ];

      setOptions(results);
    }
  }, [searchText, mapboxResults]);

  const handleSearch = (query: string) => {
    if (!query) setSearch(null);
    setSearchText(query);
  };

  const onSelect = (value: string) => {
    if (mapboxResults && mapboxResults?.features?.map((d) => d["place_name_fr"]).includes(value)) {
      const place = mapboxResults.features.find((d) => d["place_name_fr"] === value);
      flyToCoordinate(map, place?.["center"], 14);
    } else {
      flyToLocation(map, value);
      setLocation(value || undefined);
    }
    setSearch(value);
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 15,
        right: 15,
        zIndex: 40,
        padding: 8,
        //backgroundColor: "#FFFFFFaa",
        borderRadius: 8,
      }}
    >
      <AutoComplete
        popupClassName="certain-category-search-dropdown"
        popupMatchSelectWidth={500}
        style={{ width: 250 }}
        onSelect={onSelect}
        onSearch={handleSearch}
        options={options}
        value={searchText}
        onChange={setSearchText}
      >
        <Input.Search size="large" placeholder="Recherche" allowClear={true} loading={isLoading} />
      </AutoComplete>
    </div>
  );
};

export default SearchBox;
