import Content from "./Content";
import Adaptation from "./Adaptation";

//changer "inondations" partout
import logo from "../../../assets/icons/water-pollution.svg";
export const PollutionEau = {
  id: "pollution_eau",
  label: "Pollution de l'eau",
  cluster: "nature",
  clusterLabel: "Nature",
  description: (
    <div>
      <p>
        Outre les aspects quantitatifs explorés dans les deux autres sections sur l'eau, les vulnérabilités de la Wallonie portent également sur la
        qualité des eaux. D'une part une réduction de la quantité d'eau amène de manière proportionnelle à une concentration plus importante des
        polluants dans les masses d'eau et donc une qualité moindre. D'autre part les températures plus élevées pourront amener dans certaines masses
        d'eau à la prolifération de bactéries. Enfin les épisodes de pluviosité extrêmes entrainent un lessivage plus important d'engrais et
        pesticides qui sont transportés jusque dans les cours d'eau. C'est sur ce dernier point en particulier que l'analyse ci-dessous se focalise.
      </p>
    </div>
  ),

  //compléter
  aleas: [{ name: "pluviosité extrême ou abondante" }],
  expositions: [{ name: "parcelles agricoles" }, { name: "cours d'eau" }, { name: "nappes phréatiques" }],
  vulnérabilités: [{ name: "couverture du sol" }, { name: "dispositifs de rétention d'eau (haies, fascines, ...)" }],
  impacts: [{ name: "pollution de l'eau" }, { name: "santé humaine" }, { name: "perte de biodiversité" }, { name: "eutrophisation de l'eau" }],
  facteurs_aggravant: [
    { name: "type d'agriculture" },
    { name: "artificialisation des terres" },
    { name: "relief" },
    { name: "sécheresse édaphique (du sol)" },
  ],
  content: <Content />,
  adaptation: <Adaptation />,

  //changer
  icon: logo,

  //compléter
  maps: ["MESU", ""],

  //supprimer non pertinent
  associatedThemes: ["agriculture", "biodiversité", "sols", "economie", "sante", "tourisme", "infrastructures", "villes"],
};
