import { getPopulation as getPopulation_ } from "shared/population";
import population_total from "../assets/population_total.csv";
import population_fragile from "../assets/population_fragile.csv";
import usePromise from "react-use-promise";
import { geojsonMunicipalities } from "../services/geojson";

export function getPopulation() {
  return getPopulation_([population_total, population_fragile]);
}

export const usePopulation = () => {
  const [pop] = usePromise(getPopulation, []);
  const wallonianMunisipalities = geojsonMunicipalities.map((d) => d.properties.name_fr);
  return pop?.filter((p) => wallonianMunisipalities.includes(p.municipality));
};
