import Content from "./Content";
import Adaptation from "./Adaptation";

//changer "inondations" partout
import logo from "../../../assets/icons/social.svg";
export const Social = {
  id: "social",
  label: "Social",
  cluster: "social",
  clusterLabel: "Social",
  description: (
    <div>
      <p>
        La vulnérabilité de la population est une composante importante des risques climatiques. Sur cette page est présenté, par secteur statistique,
        un indicateur composite de vulnérabilité.
      </p>
    </div>
  ),

  //compléter

  content: <Content />,
  adaptation: <Adaptation />,

  //changer
  icon: logo,

  //compléter
  maps: ["", ""],

  //supprimer non pertinent
  associatedThemes: [
    "agriculture",
    "biodiversité",
    "eau",
    "sols",
    "economie",
    "energie",
    "infrastructures",
    "logements",
    "sante",
    "tourisme",
    "villes",
  ],
};
