import Content from "./Content";
import Adaptation from "./Adaptation";

//changer "inondations" partout
import logo from "../../../assets/icons/sol.svg";
export const SolsInondations = {
  id: "sols_Inondation",
  label: "Inondation des Sols",
  cluster: "nature",
  clusterLabel: "Nature",
  description: (
    <div>
      <p>
        La vulnérabilité des sols face aux épisodes de pluies extrêmes et les inondations est principalement liée à la perturbation causée par
        l'imperméabilisation et l'artificialisation des sols, ainsi qu'aux caractéristiques du sol et au relief de la région. Il se produit alors une
        érosion du sol, parfois importante, qui dégrade alors encore plus le sol et qui par ailleurs emporte les sédiments sous forme de coulées
        boueuses qui peuvent impacter fortement les infrastructures et écosystèmes qui les réceptionnent.
      </p>
    </div>
  ),

  //compléter
  aleas: [{ name: "Pluies extrêmes" }, { name: "augmentation de température" }],

  facteurs_aggravant: [
    { name: "Aménagement du territoire" },
    { name: "Imperméabilisation des sols" },
    { name: "utilisation des terres" },
    { name: "Compaction du sol" },
  ],

  expositions: [{ name: "sols agricoles" }, { name: "Réserve naturelle" }, { name: "Zones urbaines" }],

  vulnérabilités: [
    { name: "Texture du sol" },
    { name: "Structure du sol" },
    { name: "Topographie" },
    { name: "Hydrogéologie" },
    { name: "Couvert végétal" },
  ],

  impacts: [
    { name: "Dégradation du sol" },
    { name: "Erosion du sol" },
    { name: "Glissement de terrain" },
    { name: "Sédimentation dans les cours d’eau" },
    { name: "Répercussions sur les écosystèmes" },
  ],
  content: <Content />,
  adaptation: <Adaptation />,

  //changer
  icon: logo,

  //compléter
  maps: ["SolsErosion", ""],

  //supprimer non pertinent
  associatedThemes: ["agriculture", "biodiversité", "eau"],
};
