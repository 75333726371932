import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";

export default function Methodologie() {
  //const [event, setEvent] = useContext(EventContext);

  return (
//Décrire les mesures d'adaptation possible en lien avec les vulnérabilités explorées sur les cartes
    <div>
      <p>
      L’étude prospective sur les sécheresses <a href="https://www.iweps.be/wp-content/uploads/2020/09/CAPRO-4-secheresse-final2.pdf">^[DEMNA-IWEPS, 2020]</a> souligne que des mesures locales de préservation de la ressource doivent être mises en place dès aujourd’hui pour obtenir des effets bénéfiques d’ici quelques décennies. Il s'agit notamment de restauration de milieux naturels, adaptation des modes de production en agriculture, sylviculture et dans l’industrie, économies d’eau et approvisionnement alternatifs.
      Toutes les solutions basées sur la nature évoquées pour lutter contre les inondations contribuent également à une plus grande résilience contre les sécheresses : freiner le ruissellement des eaux au sein des bassins versants et de favoriser l’infiltration de l’eau dans les sols puis vers les nappes. Afin de favoriser l’infiltration de l’eau, des aménagements du paysage au sein des bassins versants peuvent être mis en place comme les noues, les fossés, les puits d’infiltration, les zones humides ou encore des haies. L’augmentation de l’infiltration de l’eau passe également pour une diminution du taux d’artificialisation et d’imperméabilisation des sols en Wallonie. En ville, l’utilisation de matériaux de construction plus perméable et la mise en place d’infrastructure végétalisée et de zones perméables comme des jardins de pluies ou des bassins d’infiltration sont à prioriser. Les dispositifs d’infiltration ou de temporisation des eaux peuvent être intégrés aux infrastructures à construire ou existantes et générer des co-bénéfices pour la biodiversité, la fraicheur et l’esthétique. 
      Au niveau des périodes de sécheresse, l’augmentation des demandes en eau de différents secteurs durant une même période, amenant potentiellement à des conflits d’usage de l’eau, appellent à des mesures de sobriété dans l’utilisation des eaux. Dans ce sens, une recherche d’alternatives aux cultures hautement consommatrices d’eau doit également être soutenue. La diversification des sources d’eau comme les eaux de pluie, les eaux de sorties des stations d’épuration pour l’agriculture ou de certaines eaux industrielles doit être favorisée. 
      Dans les zones déjà trop imperméabilisées où la rétention pour l’infiltration d’eau vers les nappes phréatiques n’est pas aisée, la collecte et le stockage de l’eau de pluie en vue de sa réutilisation locale est une solution à privilégier. Au niveau du Schéma Régional des Ressources en Eau, l’approvisionnement des régions vulnérables au manque d’eau (comme l’Ardenne) est amélioré grâce à la construction de plusieurs centaines de kilomètres de conduites d’interconnexion entre les réseaux de distribution par la SWDE. La surveillance de l’évolution des ressources en eau de surface et souterraines doit se poursuivre. La coopération transfrontalière est également importante dans la stratégie d’adaptation du secteur eau.
      <a href="">[(Voir fiche mesure XX)]</a>
      </p>
     
    </div>
  );
}
