//supprimer selon pertinence
import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";
import { useLocation } from "../../../components/Thematic/Utils/Location";
export default function Content() {
  //@ts-ignore
  const [event, setEvent] = useContext(EventContext);
  const [location, setLocation] = useLocation();

  return (
    <div>
      <h4>Aléa</h4>
      <p>
        La wallonie a déjà connu de nombreux épisodes d'inondations. L'épisode de juillet 2021 marque encore les mémoires, en particulier dans le
        bassin de la Vesdre. Les changements climatiques entraîneront une plue grande irrégularité des précipitations que ce que nous avons connu
        jusqu’à présent. Cela se traduira par davantage de précipitations extrêmes alors que le nombre de jours de précipitations diminuera en été.
        Les pluies seront donc plus concentrées que par le passé. Les projections climatiques pour la Wallonie suggèrent que dans un monde à +2° un
        épisode comme celui de 2021 qui par le passé était un évènement considéré avec une période de retour de 50 ans, pourrait devenir un évènement
        dont la période de retour est de 10 ans. Par ailleurs dans les scénarios de projection d'un monde à +3° et +4° les inondations se font moins
        fréquentes et ce sont les sécheresses qui domineront en Wallonie. Par ailleurs les projections suggèrent que l’intensité des pluies extrêmes
        pourrait augmenter de +66%. Les inondations par ruissellement pourraient donc être également plus fréquentes.
      </p>
      <h4>Exposition</h4>
      <p>
        C'est dans le bassin de la Vesdre que le risque d'inondations s'amplifiera le plus. Dans le reste de la Wallonie l'aggravation du phénomène
        est moins marquée. Il restera toutefois existant. Sont exposés au risque d'inondation :
        <ul>
          <li>238 294 habitations (17,98%). Cela représente approximativement 478 216 citoyens </li>
          <li>29 105 commerces, services et institutions du secteur tertiaire (38,89%)</li>
          <li>9 544 entreprises industrielles et d’artisanat (36,76%)n</li>
          <li>
            parmi les bâtiments accueillant des personnes vulnérables on compte : 157 maisons de repos, 58 hopitaux et 216 crèches situées en zone à
            risque
          </li>
        </ul>
      </p>

      <h4>Facteurs aggravants</h4>
      <p>
        Ces inondations et le phénomène de ruissellement sont aggravés par l’imperméabilisation des sols, conséquence directe de l’augmentation de
        l’artificialisation du territoire. Au plus une surface est imperméabilisé, au plus l’eau ruissellera sur cette surface au lieu de s’infiltrer
        et rejoindre les nappes phréatiques.
      </p>
      <h4>Impacts</h4>
      <p>
        Les inondations ont un impact sur les bâtiments et l'infrastructure. Depuis l'impossibilité de les utiliser pendant une période temporaire,
        jusqu'à leur destruction complète en passant par la détérioration progressive liée à l'humidité. Les inondations impactent également la santé
        et la vie des citoyens avec des décès potentiels et des maladies suite à l'insalubrité des logements inondés.
      </p>
      <h4>Ce que montre la carte</h4>
      <p>
        Sur cette carte vous pouvez explorer le risque d'aggravation des zones d'aléa d'inondation selon les scénarios climatiques futurs. Les
        scénarios peuvent être sélectionnés en haut de la carte.
      </p>
      <p>
        La partie gauche de la carte représente les zones d'aléa d'inondation en vigueur. L'aléa est une évaluation de la susceptibilité d'une zone
        d'être inondée caractérisée par une combinaison de (i) la récurrence (période de retour) d'une inondation dans cette zone et (ii) son
        importance (hauteur d'eau ou débit de pointe). Il est important de noter que la récurence (période de retour) est estimée sur base des données
        historiques. Cela ne prend donc pas en compte les projections climatiques. Il est prévu d'intégrer l'impact des changements climatique dans la
        prochaine révision de ces cartes d'aléa (2026). En attendant, la partie droite de la carte indique pour chaque zone d'aléa la probabilité que
        celui-ci s'aggrave dans le futur selon le scénario sélectionné.
      </p>
      <p>
        Il est à noter que dans la thématique sol sont identifiées des zones étroitement reliées aux zones d'aléa d'inondation. Il s'agit des zones
        qui, si elles sont imperméabilisées, contribueront fortement à aggraver ce risque d’inondation et qu’il faudrait donc préserver.
      </p>

      <h4>Rapport méthodologique complet</h4>
      <p>
        <a href="">[Pirlot, C et al. 2024]</a>
      </p>
    </div>
    // Sur le dernier paragraphe il faudrait un lien vers la page "sols - inondations".

    // explorer également la possibilité d'une visualisation dynamique sur la carto : on sélectionne une zone d'aléa et cela sélectionne automatiquement tous les bassins versants contributifs qui amènent les pluies jusqu'à cette zone d'inondation.

    // si c'est possible alors idéalement le lien vers la thématique sol se ferait même via la carto : on sélectionne une zone d'aléa, cela affiche l'ensemble des bassins versants cont
  );
}
