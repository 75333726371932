"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPopulation = void 0;
const csv_1 = require("./csv");
function getPopulation(urls) {
    return __awaiter(this, void 0, void 0, function* () {
        const files = yield Promise.all(urls.map((filename, i) => __awaiter(this, void 0, void 0, function* () {
            const file_ = yield (0, csv_1.load_csv)(filename);
            return file_;
        })));
        const result = files.flat(1).reduce((acc, file) => {
            const existing = acc.find((item) => item.Commune === file.Commune);
            if (existing) {
                Object.assign(existing, file);
            }
            else {
                acc.push(Object.assign({
                    Commune: file.Commune,
                    Fragile: "0",
                    Total: "0",
                }, file));
            }
            return acc;
        }, []);
        return result.map((item) => ({
            municipality: item.Commune,
            fragile: parseInt(item.Fragile, 10),
            total: parseInt(item.Total, 10),
        }));
    });
}
exports.getPopulation = getPopulation;
