"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.load_csv = void 0;
function decode(response) {
    return __awaiter(this, void 0, void 0, function* () {
        const buffer = yield response.arrayBuffer();
        const decoder = new TextDecoder("Latin1");
        const text = decoder.decode(buffer);
        return text;
    });
}
function load_csv(url) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch(url);
        if (response.ok) {
            const text = (yield decode(response)).replace(/[^\x00-\x7Féèêàçàâûù\']/g, "").replace(/\u0000/g, "");
            const lines = text.split("\n").map((line) => line.trim());
            const header = lines[0].split(";").map((h) => h.trim());
            const data = lines.slice(1).map((line) => {
                const values = line.split(";");
                const obj = {};
                for (let i = 0; i < header.length; i++) {
                    if (values[i].startsWith("=")) {
                        values[i] = values[i].substring(1);
                    }
                    if (values[i].startsWith('"') && values[i].endsWith('"')) {
                        values[i] = values[i].substring(1, values[i].length - 1);
                    }
                    obj[header[i]] = values[i];
                }
                return obj;
            });
            return data;
        }
        throw new Error(`Failed to load CSV from ${url}`);
    });
}
exports.load_csv = load_csv;
