import Content from "./Content";
import Adaptation from "./Adaptation";

//changer "inondations" partout
import logo from "../../../assets/icons/drought.svg";
export const SecheressesHydrographiques = {
  id: "secheresseshydro",
  label: "Secheresses Hydrographiques",
  cluster: "nature",
  clusterLabel: "Nature",
  description: (
    <div>
      <p>
        Les projections climatiques nous indiquent une accentuation des phénomènes climatiques extrêmes ainsi qu’une saisonnalité plus marquée, ce qui
        mènera à des sécheresses plus fréquentes et plus intenses. On distingue différents types de sécheresses : une sécheresse "météorologique" sont
        une absence prolongée de pluies; une sécheresse édaphique (du sol) est un manque d'eau disponible dans le sol pour les plantes; enfin la
        sécheresse "hydrologique" est une baisse importante dans la quantité des masses d'eau souterraine et de surface (déficit de débit des cours
        d'eau, niveau bas des nappes phréatiques, ...).
      </p>
    </div>
  ),

  //compléter
  aleas: [{ name: "sécheresse météorologique" }],
  expositions: [{ name: "sols agricoles et naturels" }, { name: "Cours d'eau" }, { name: "Nappes phréatiques" }],
  vulnérabilités: [
    { name: "teneur en matière organique des sols" },
    { name: "relief" },
    { name: "couvert végétal" },
    { name: "débit d'étiage" },
    { name: "perméabilité de l'aquifère" },
  ],
  impacts: [{ name: "stress hydrique pour la végétation" }, { name: "réduction des débits d'étiage" }, { name: "réduction du niveau des nappes" }],
  facteurs_aggravant: [{ name: "artificialisation des terres" }, { name: "gestion de l'eau" }],
  content: <Content />,
  adaptation: <Adaptation />,

  //changer
  icon: logo,

  //compléter
  maps: ["SolsTeneurEau", "MESU", "MESO", "ZoneDistributionEau"],

  //supprimer non pertinent
  associatedThemes: [
    "agriculture",
    "biodiversité",
    "sols",
    "economie",
    "energie",
    "infrastructures",
    "logements",
    "sante",
    "social",
    "tourisme",
    "villes",
  ],
};
