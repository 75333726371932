import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";

export default function Methodologie() {
  //const [event, setEvent] = useContext(EventContext);

  return (
//Décrire les mesures d'adaptation possible en lien avec les vulnérabilités explorées sur les cartes
    <div>
      <p> 
      Des techniques visant à minimiser l’incapacité des sols de retenir et de transmettre l’eau peuvent renforcer la résilience du sol face à l'augmentation des températures et à la réduction des précipitations. Telles les couvertures végétales qui maintiennent l'humidité du sol, limitent l'évaporation et favorisent l'infiltration de l'eau et l’augmentation de la teneur en carbone du sol par l'ajout de matière organique favorisant le maintien de l'humus.  
      <a href="">[(Voir fiche mesure XX)]</a>
      </p>
      <p> 
      Les sols urbains artificialisés sont particulièrement vulnérables à la sécheresse, tout comme les sols imperméabilisés qui entravent l'infiltration de l'eau. Pour pallier ces problèmes, les habitants peuvent adopter des méthodes telles que la création de jardins de pluie pour une gestion l'eau de pluie et création d’espace vert. Désimperméabiliser les sols est aussi favorable à la végétalisation, contribuant à améliorer la rétention d'eau. 
      <a href="">[(Voir fiche mesure XX)]</a>
      </p>
      <p> 
      La restauration et la protection des sols stratégiques contre la sécheresse sont essentielles. Ces sols (soumis à la <a href="https://agriculture.wallonie.be/bcae-2-protection-des-zones-humides-et-des-tourbieres">BCAE 2</a>) pouvant stocker une quantité importante d’eau peuvent renforcer la résilience des différents compartiments des écosystèmes en maintenant un équilibre hydrique.  
      <a href="">[(Voir fiche mesure XX)]</a>
      </p>
    </div>
    
  );
}
