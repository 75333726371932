import Content from "./Content";
import Adaptation from "./Adaptation";

//changer "inondations" partout
import logo from "../../../assets/icons/dust.svg";
export const Air = {
  id: "air",
  label: "Air",
  cluster: "ville",
  clusterLabel: "Ville",
  description: (
    <div>
      <p>
        En Europe, la qualité de l’air est une des préoccupations majeures des dernières décennies, à laquelle s’est ajoutée plus tard la lutte contre
        les changements climatiques. L’inventaire des différentes sources de pollution atmosphérique, leur surveillance et les mesures contraignantes
        prises par l’Union Européenne a permis de réduire fortement les émissions. Toutefois, des épisodes de fortes pollutions continuent à être
        enregistrés lors d’évènements météorologiques particuliers comme les inversions de température en hiver ou les vagues de chaleur en été, avec
        des effets directs sur la santé (maladies cardio-respiratoires) et sur les écosystèmes (réduction de la croissance).
      </p>
    </div>
  ),

  //compléter
  aleas: [{ name: "vague de chaleur" }, { name: "précipitations" }, { name: "inversions de températures" }, { name: "vent" }],
  expositions: [{ name: "population" }, { name: "écosystèmes" }],
  facteurs_aggravant: [{ name: "urbanisation" }, { name: "chauffage résidentiel" }, { name: "activités humaines" }],
  vulnérabilités: [{ name: "végétalisation" }, { name: "indices socio-économiques" }, { name: "état de santé" }],
  impacts: [{ name: "maladies cardio-respiratoires" }, { name: "dégradation de la biodiversité" }],
  content: <Content />,
  adaptation: <Adaptation />,

  //changer
  icon: logo,

  //compléter
  maps: ["Air", ""],

  //supprimer non pertinent
  associatedThemes: [
    "agriculture",
    "biodiversité",
    "eau",
    "sols",
    "economie",
    "energie",
    "infrastructures",
    "logements",
    "sante",
    "social",
    "tourisme",
    "villes",
  ],
};
