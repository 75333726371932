import React, { useContext } from "react";
import { EventContext, EventHighlight, useFlyTo } from "../../../helpers/Event";
import ScatterPlot from "./ScatterPlot";
import { usePopulation } from "../../../hooks/population";
import { useLocation } from "../../../components/Thematic/Utils/Location";
import { useMap } from "react-map-gl";

export default function Content() {
  const flyTo = useFlyTo();
  const [location] = useLocation();
  const population = usePopulation() || [];

  return (
    <div>
      <p>
        TEST TEST Les principales pressions sur les milieux et les espèces sont la perte directe des surfaces d’habitat et leur fragmentation,
        l’intensification des pratiques agricoles et sylvicoles, la pollution (engrais azotés, pesticides, lumineuse) et les espèces exotiques
        envahissantes. A ces moteurs de perte de biodiversité se superpose celui des changements climatiques, aggravant les pressions pesant sur les
        écosystèmes.
      </p>
      <p>
        Identifier les vulnérabilités des systèmes naturels face au changement climatique est une étape essentielle pour prioritiser les actions
        visant à améliorer leur résilience. La stratégie «Biodiversité 360» de la Région wallonne vise notamment à «Préserver la biodiversité et
        restaurer les populations d'espèces et les habitats naturels dégradés». Or, le rapport d’avril 2022 de la plateforme wallonne pour le GIEC
        identifie plusieurs risques et impacts du changement climatique sur la biodiversité: modification des aires de distribution des espèces,
        modification des interactions entre espèces dues aux changements phénologiques, naturalisation et invasion d’espèces exotiques envahissantes…
        les événements extrêmes de sécheresse et canicule impacteraient plus particulièrement certains habitats tels que les tourbières ou prairies de
        fauches, et les espèces des milieux froids (plateaux ardennais). Certaines des principales essences de production de bois en Wallonie (hêtre,
        épicéa, chêne pédonculé…) sont également susceptible de subir des dépérissements et déplacements d’aires à cause des modifications de régime
        hydrique induites par le CC. Il est donc important de tenir compte des modifications d’aires et des vulnérabilités des habitats naturels dans
        les stratégies régionales. Ainsi des mesures de gestion peuvent être proposées pour réduire la fragmentation des habitats, augmenter la
        connectivité et l’hétérogénéité des habitats naturels, maintenir la diversité taxonomique, phylogénétique et fonctionnelle, et augmenter la
        résilience des écosystèmes aux évènements extrêmes.
      </p>
      <EventHighlight eventId="bioDiversité">
        <p id="bioDiversité">
          Si le changement climatique est une pression complémentaire non négligeable sur la biodiversité extraordinaire, il est également susceptible
          d’impacter négativement la biodiversité dite «ordinaire» et la capacité des habitats à fournir des services écosystémiques tels que le
          stockage de carbone dans le sol, la pollinisation, la valeur récréative et sanitaire… Notamment, les zones urbaines et agricoles peuvent
          abriter une biodiversité importante et agir comme corridors au sein du réseau écologique
        </p>
      </EventHighlight>
      <p>
        Cette première étape fait l’état des lieux des initiatives proposées en Région Wallonne ainsi que des bases de données et outils disponibles.
        L’état des lieux porte également sur les analyses de vulnérabilité et stratégies d’adaptation dans ce domaine, proposées en Belgique au niveau
        régional (région Bruxelles-Capitale, région flamande) et national, ainsi que dans les pays voisins.
      </p>
      <p>
        Les attentes spécifiques relatives au projet pour la thématique «Services écosystémiques (SES)», «Nature et Biodiversité» et «Forêts», ainsi
        que la manière dont le projet pourra y répondre sont reprises en Annexe 1.
      </p>
      <EventHighlight eventId="test2">
        <p>
          Dans ce rapport, nous rassemblons les trois thématiques «Nature et Biodiversité», «Services écosystémiques» et «forêts» au vu des
          similarités des thématiques, car l’approche est généralement très similaire, que ce soit au niveau des outils ou des méthodologies. La
          figure suivante rassemble tous les éléments présentés ci-dessus dans le cadre conceptuel des chaines d’impacts.
        </p>
        {population && (
          <ScatterPlot
            title="Degré d'exposition climatique et proportion de la population à risque"
            data={population}
            selected={[location]}
            select={(location: string) => {
              flyTo(location, "if search, force going to location");
            }}
          />
        )}
      </EventHighlight>
    </div>
  );
}
