import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";

export default function Methodologie() {
  //const [event, setEvent] = useContext(EventContext);

  return (
//Décrire les mesures d'adaptation possible en lien avec les vulnérabilités explorées sur les cartes
    <div>
      <p> 
      Une des mesures d'adaptation des sols dans les zones urbaines face aux inondations est la désimperméabilisation des sols et la réduction de l'artificialisation afin de restaurer les propriétés naturelles du sol favorisant l'infiltration, le stockage et la transmission hydrique. Cela implique l'adoption de mesures visant à limiter l'expansion de l'artificialisation.<a href="">[(Voir fiche mesure XX)]</a>
      </p>
      <p>
      Les inondations engendrent d'autres aléas qui posent un danger, tels que les érosions, les coulées de boue et les glissements de terrain. Ces risques peuvent être freinés par la plantation de haies et le boisement des sols nus, pour minimiser le ruissellement et favoriser l'infiltration vers les nappes phréatiques. <a href="">[(Voir fiche mesure XX)]</a> 
      </p>
    </div>
  );
}
