import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";

export default function Methodologie() {
  //const [event, setEvent] = useContext(EventContext);

  return (
//Décrire les mesures d'adaptation possible en lien avec les vulnérabilités explorées sur les cartes
    <div>
      <p>
Tout comme pour les risques d'inondations et de sécheresses, les facteurs aggravants et d'adaptation sur lesquels il faut travailler sont :
<ul>
  <li>stopper l'artificialisation des sols <a href="">[(Voir fiche mesure XX)]</a></li>
  <li>favoriser la transition vers l'agroécologie et une utilisation toujours moindre des engrais et pesticides qui peuvent affecter la qualité des sols et de l'eau <a href="">[(Voir fiche mesure XX)]</a></li>
  <li>améliorer l'occupation du sol en densifiant le couvert végétal dans le temps et dans l'espace, tant pour les zones naturelles que pour les parcelles agricoles. Favoriser également les éléments naturels qui freinent le ruissellement de l'eau (haies). <a href="">[(Voir fiche mesure XX)]</a></li>
  </ul>
      </p>
     
    </div>
  );
}
