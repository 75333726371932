//supprimer selon pertinence
import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";
import { useLocation } from "../../../components/Thematic/Utils/Location";
export default function Content() {
  //@ts-ignore
  const [event, setEvent] = useContext(EventContext);
  const [location, setLocation] = useLocation();

  return (
    <div>
      <h4>Aléa</h4>
      <p>
        Les changements climatiques entraîneront une plue grande irrégularité des précipitations que ce que nous avons connu jusqu’à présent. Cela se
        traduira par davantage de précipitations extrêmes alors que le nombre de jours de précipitations diminuera en été. Les pluies seront donc plus
        concentrées que par le passé. Les projections suggèrent que l’intensité des pluies extrêmes pourrait augmenter de +66%.
      </p>
      <h4>Facteurs aggravants</h4>
      <p>
        Le ruissellement est aggravé par l’imperméabilisation des sols, conséquence directe de l’augmentation de l’artificialisation du territoire.
        Les normes plus restrictives en matière d'engrais (directive nitrates) peuvent faire espérer une réduction du risque en limitant la source de
        pollution.
      </p>
      <h4>Exposition</h4>
      <p>
        Des parcelles agricoles sur lesquels des intrants et pesticides ont été répandus et exposées à des fortes pluies vont subir un processus de
        "lessivage" par lequel les engrais et pesticides vont être emportés par les eaux et transportées jusqu'à des masses d'eau de surface ou
        souterraines.
      </p>

      <h4>Vulnérabilité</h4>
      <p>
        Selon l'intensité des pluies, la couverture végétale des espaces traversées par les ruissellements ainsi que les éventuels dispositifs de
        rétention d'eau, une quantité plus ou moins importante des engrais et pesticides atteindra la destination finale de l'eau.
      </p>
      <h4>Impacts</h4>
      <p>
        A ce jour l'impact économique et sur la santé de l'effet des changements climatiques sur la pollutions des masses d'eau n'est pas quantifié ni
        caractérisé.
      </p>
      <h4>Ce que montrent les cartes</h4>
      <p>
        <p>
          Sur ces cartes vous pouvez explorer le risque d'augmentation de la pollution des eaux en raison des changements climatiques. Les scénarios
          futurs de changements climatiques et de type d'agriculture (intensive, agroécologie) peuvent être sélectionnés en haut de la carte.
        </p>
        <p>
          <b>Risque de pollution de l'eau</b>
          C'est à dire le risque que les engrais azotés et pesticides répandus sur les parcelles agricoles ne soient emportés vers des masses d'eau de
          surface. La partie gauche de la carte montre les parcelles agricoles potentiellement source d'azote et de pesticies facilement emportés par
          les eaux en fonction de la combinaison des fortes pluies attendues dans la zone, du type de couverture végétale de la parcelle et du type de
          culture. La partie droite représente le risque d'aggravation de la qualité des masses d'eau (nappes phréatiques et cours d'eau). Les zones
          les plus à risque de pollution aggravée sont représentées en rouge.
        </p>
      </p>
      <h4>Rapport méthodologique complet</h4>
      <p>
        <a href="">[XXX et al. 2024]</a>
      </p>
    </div>
  );
}
