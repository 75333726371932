import Content from "./Content";
import Adaptation from "./Adaptation";

//changer "inondations" partout
import logo from "../../../assets/icons/logement.svg";
export const Logement = {
  id: "logement",
  label: "Logement",
  cluster: "logement",
  clusterLabel: "logement",
  description: (
    <div>
      <p>
        Le dérèglement climatique et les évènements météorologiques extrêmes qui y sont associés pourraient sérieusement affecter les logements en
        Wallonie. Nous explorons ici l'exposition des habitations aux risques climatiques physiques tels que les vagues de chaleur, les inondations et
        les feux de forêts.{" "}
      </p>
    </div>
  ),

  //compléter
  aleas: [{ name: "Vague de chaleur" }, { name: "Inondation" }, { name: "Feux de forêts" }],
  expositions: [{ name: "Logements" }],
  vulnérabilités: [{ name: "Composants du bâtiment" }, { name: "Caractéristiques socio-économiques des habitants" }],
  impacts: [{ name: "Santé" }, { name: "Dégâts aux habitations" }],
  content: <Content />,
  adaptation: <Adaptation />,

  //changer
  icon: logo,

  //compléter
  maps: ["", ""],

  //supprimer non pertinent
  associatedThemes: ["biodiversité", "energie", "infrastructures", "sante", "social", "villes"],
};
