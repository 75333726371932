//supprimer selon pertinence
import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";
import { usePopulation } from "../../../hooks/population";
import { useLocation } from "../../../components/Thematic/Utils/Location";
export default function Content() {
  //@ts-ignore
  const [event, setEvent] = useContext(EventContext);
  const [location, setLocation] = useLocation();
  const population = usePopulation() || [];

  return (
    <div>
      <h4>Aléa</h4>
      <p>
        Le danger ou l’aléa correspond à la fréquence (nombre d’épisodes) ou la durée-intensité (nombre de degré-jour) d’une vague de chaleur, ou
        encore l’intensité d’un stress thermique identifié par le dépassement de seuils d’un indice de température ressentie. Une vague de chaleur est
        définie par la succession de minimum 5 jours de températures maximales supérieures ou égales à 25°C, dont au moins trois sont supérieures ou
        égales à 30°C. Pour la Belgique, les projections indiquent que d’ici à la fin du siècle, il y aurait entre 7 et 34 jours de chaleur
        (température supérieure ou égale à 30°C) de plus par rapport à la moyenne 1976-20061. Par ailleurs, il est aussi attendu qu’au moins 1 vague
        de chaleur se produise chaque été dès la moitié du XXIème siècle2. La Wallonie n’échappera donc pas à une recrudescence du phénomène.
      </p>
      <h4>Facteurs aggravants</h4>
      <p>
        Plusieurs facteurs aggravent le phénomène d'îlot de chaleur urbain. Tout d'abord, l'urbanisation conduit à une augmentation des surfaces
        imperméables, comme le béton et l'asphalte, qui absorbent et retiennent la chaleur, exacerbant ainsi les températures locales. De plus, la
        densité des bâtiments restreint la circulation de l'air et favorise la formation de zones de chaleur emprisonnée. L'utilisation généralisée de
        systèmes de climatisation qui rejettent la chaleur excédentaire en extérieur augmente la température ambiante de la ville, tandis que la
        diminution des espaces verts réduit la capacité de régulation thermique naturelle de la ville. Enfin, les activités humaines telles que le
        trafic routier et les industries émettent des polluants atmosphériques, contribuant ainsi à la formation d'ozone troposphérique et
        intensifiant les effets de l'îlot de chaleur urbain.
      </p>
      <h4>Exposition</h4>
      <p>
        L’Agence Européenne de l’Environnement reconnait les vagues de chaleur comme la plus grande menace sanitaire pour la population européenne. La
        population des villes constitue donc la victime principale du phénomène d’îlot de chaleur urbain, dont les risques sur la santé et le
        bien-être sont considérables.
      </p>
      <h4>Vulnérabilité</h4>
      <p>
        La Wallonie sera vulnérable aux îlots de chaleur urbains si sa population y est particulièrement vulnérable. Au sein de cette population, ce
        sont les conditions socio-économiques, telles que le niveau de revenu ou et la qualité du logement, qui jouent un rôle déterminant. Les
        personnes vivant dans des situations de précarité économique ou dans des logements mal isolés sont plus susceptibles d'être vulnérables. De
        plus, les caractéristiques démographiques telles que l'âge (les enfants et les personnes âgées étant plus sensibles) ou l'état de santé
        préexistant (comme les maladies cardiovasculaires ou respiratoires) influencent la vulnérabilité. A l’inverse, l’accès à des espaces verts
        pour s’y rafraichir lors d’épisodes de fortes chaleurs ou la mise en place de plans d’urgences permet d’atténuer les effets néfastes des
        vagues de chaleur sur la population.
      </p>
      <h4>Ce que montrent les cartes</h4>
      <p>
        La carte présentée ci-contre affiche le risque d’aléa d’îlot de chaleur urbain pour le territoire de la Wallonie. En naviguant sur celle-ci
        vous pouvez visualiser le contraste existant entre les milieux ruraux et urbains d’une part, et identifier plus précisément les zones du
        territoire particulièrement affectées à l’échelle locale. Les résultats peuvent être affichés selon différents scénarios (situations actuelle
        et futures) à sélectionner en haut de la page. La cartographie tient compte de la morphologie urbaine (ex. occupation du sol, densité de
        population) et, pour le présent, expose les inégalités rencontrées par les populations les plus précaires sur base d’indices de vulnérabilité
        socio-économique.
      </p>
      <h4>Rapport méthodologique complet</h4>
      <p>
        <a href="">[Wyard, C. et al. 2024]</a>
      </p>
    </div>
  );
}
