import axios from "axios";
import { UseQueryResult, useQuery } from "react-query";
import useDebounce from "./useDebounce";
import { FeatureCollection } from "geojson";

export const getMapboxLocations = async (search) => {
  //console.log("searching", search, encodeURIComponent(search));
  if (!search || search.length <= 3) return null;
  const { data } = await axios.get<
    FeatureCollection & {
      features: Array<
        FeatureCollection["features"][number] & {
          place_name_fr: string;
        }
      >;
    }
  >(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${search
      .split(" ")
      .join("+")}.json?country=be&bbox=2.8,49.5,6.5,50.8&proximity=4.904558793589274%2C50.46373370694795&language=fr&access_token=${
      process.env.REACT_APP_MAPBOX_TOKEN
    }`,
  );
  return data;
};

export const useMapboxSearch = (search) => {
  const debouncedSearch = useDebounce(search, 1000);
  return useQuery(["mapboxSearch", debouncedSearch], () => getMapboxLocations(debouncedSearch), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
  });
};
