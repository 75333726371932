import ResizeDetector from "react-resize-detector";
import { useEffect, useState } from "react";
export function useSize(ref = window) {
  const [size, setSize] = useState<{
    width: number | undefined;
    height: number | undefined;
  }>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    if (!ref) return;
    function handleResize() {
      setSize({
        width: (ref as unknown as Element).clientWidth,
        height: (ref as unknown as Element).clientHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref]);
  return size;
}

export const withSize = <T extends { ref?: any }>(Component: React.ComponentType<T>) => {
  return (props: T) => {
    return (
      <ResizeDetector handleWidth handleHeight>
        {({ width, height }) => {
          return <Component {...props} width={width} height={height} />;
        }}
      </ResizeDetector>
    );
  };
};
