//supprimer selon pertinence
import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";
import { useLocation } from "../../../components/Thematic/Utils/Location";
export default function Content() {
  //@ts-ignore
  const [event, setEvent] = useContext(EventContext);
  const [location, setLocation] = useLocation();

  return (
    <div>
      <h4>Ce que montrent les cartes</h4>
      <p>
        La carte représente l'indicateur composite de vulnérabilité. Cet indicateur a été construit en croisant des données disponibles à l'échelle
        des secteurs statistiques et permettant de caractériser plusieurs dimensions de la vulnérabilité socio-économique du territoire. Notamment :
        <ul>
          <li>Données de population et projections de la population en 2050</li>
          <li>Age</li>
          <li>Revenu médian</li>
          <li>Accès aux droits fondamentaux</li>
          <li>Bénéficiaires BIM</li>
        </ul>
      </p>
      <h4>Rapport méthodologique complet</h4>
      <p>
        <a href="">[Loozen, Y. et al. 2024]</a>
      </p>
    </div>
  );
}
