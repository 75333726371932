import { useMaps } from "./maps";
import { useQueryParam, StringParam } from "use-query-params";
import { useTheme } from "./theme";

export const useMap = () => {
  const { data: maps = [] } = useMaps();
  const [map, setMap] = useQueryParam("map", StringParam);
  const [theme] = useTheme();
  return [maps.find((t) => t.id === map) || (theme && theme.maps && maps.find((t) => t.id === theme.maps?.[0])) || null, setMap] as const;
};
