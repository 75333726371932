import Content from "./Content";
import Adaptation from "./Adaptation";

//changer "inondations" partout
import logo from "../../../assets/icons/sol.svg";
export const SolsSecheresse = {
  id: "sols_sécheresse",
  label: "Sécheresse des Sols",
  cluster: "nature",
  clusterLabel: "Nature",
  description: (
    <div>
      <p>
        Le sol est un élément essentiel au bon fonctionnement des écosystèmes, aux services qu'ils fournissent et à la solidité des infrastructures
        qu'il accueille. Les dérèglements climatiques vont entrainer une variation plus importante et plus fréquente de la disponibilité en eau dans
        ces sols. Ceci aura des impacts majeurs sur les caractéristiques structurelles des sols et leur capacité à accueillir la végétation.
      </p>
    </div>
  ),

  //compléter
  aleas: [{ name: "Augmentation de la température" }, { name: "Diminution de précipitations" }],

  facteurs_aggravant: [
    { name: "Urbanisation" },
    { name: "Artificialisation des sols" },
    { name: "Utilisation des sols" },
    { name: "Gestion de l’eau" },
  ],

  expositions: [{ name: "sols agricoles" }, { name: "Réserves naturelles" }, { name: "Zones urbaines" }],

  vulnérabilités: [
    { name: "Texture du sol" },
    { name: "Structure du sol" },
    { name: "Potentiel de rétention en eau" },
    { name: "Teneur en matière organique" },
    { name: "Topographie" },
    { name: "Drainage" },
    { name: "Epaisseur du sol" },
    { name: "Couvert végétal" },
  ],

  impacts: [
    { name: "Pertes de rendements agricoles" },
    { name: "dégradation des sols sensible" },
    { name: "Répercussion sur des zones humides" },
    { name: "Exposition aux feux de forêt" },
    { name: "Déclin de la Biodiversité" },
  ],
  content: <Content />,
  adaptation: <Adaptation />,

  //changer
  icon: logo,

  //compléter
  maps: ["", ""],

  //supprimer non pertinent
  associatedThemes: ["agriculture", "biodiversité", "eau"],
};
