import { useEffect, useRef, useState } from "react";
import * as Plot from "@observablehq/plot";
import _ from "lodash";
import * as d3 from "d3";
import { useResizeDetector } from "react-resize-detector";
import numeral from "numeral";

const ScatterPlot = ({ title, data, selected, select }) => {
  const ref = useRef();
  const { width: componentWidth, ref: refParent } = useResizeDetector();
  const [chart, setChart] = useState(null);
  //console.log("data", data);
  useEffect(() => {
    if (data && componentWidth) {
      const chart = Plot.plot({
        title,
        width: componentWidth,
        height: componentWidth * 0.6,
        marginRight: 30,
        style: {
          fontSize: "10px",
        },
        x: {
          type: "log",
          grid: true,
          label: "Facteur d'exposition",
          //tickFormat: d => `${numeral(d).format("a")}`
        },
        y: {
          //type: "log",
          //domain: [0, 100],
          percent: true,
          tickFormat: (d) => `${d}%`,
          label: "Proportion de la population à risque",
        },
        r: {
          range: [0, 30],
        },
        marks: [
          Plot.ruleY([0]),
          // //Plot.ruleY([1], { stroke: "grey", strokeDasharray: 2 }),
          // //Plot.link(data, { x1: "total2014", y1: "ratioImport2014", x2: "total2022", y2: "ratioImport2022", markerEnd: "arrow", stroke: "grey"}),
          // //Plot.dot(data, { x: "total2014", y: "ratioImport2014", r: 2, fill: "grey", stroke: "white"}),
          Plot.dot(data, {
            x: "total",
            y: (d) => d.fragile / d.total,
            r: "total",
            fill: (d) => (selected.includes(d.municipality) ? "red" : "grey"),
            opacity: (d) => (selected.includes(d.municipality) ? 1 : 0.6),
            stroke: (d) => "white",
            strokeWidth: (d) => 1,
          }),
          Plot.dot(
            data.filter((d) => selected.includes(d.municipality)),
            {
              x: "total",
              y: (d) => d.fragile / d.total,
              r: "total",
              fill: "red",
              stroke: "black",
              strokeWidth: 2,
            },
          ),
          Plot.tip(
            data,
            Plot.pointer({
              x: "total",
              y: (d) => d.fragile / d.total,
              r: "total",
              title: (d) =>
                `${d.municipality} \nPopulation totale: ${numeral(d.total).format("0,0.")}\nPopulation à risque ${numeral(d.fragile).format(
                  "0,0.",
                )} (${numeral(d.fragile / d.total).format("0.%")})\nExposition ${numeral(0.43).format("0.[0]%")}\n`,
            }),
          ),
        ],
      });

      d3.select(chart)
        .selectAll("circle")
        .attr("cursor", "pointer")
        .on("click", (e, d) => {
          //console.log("click", e, d, data[d].municipality);
          select(data[d].municipality);
        });

      ref.current.append(chart);
      setChart(chart);

      return () => chart.remove();
    }
  }, [data, selected, componentWidth]);

  return (
    <div ref={refParent}>
      {data && (
        <>
          <div ref={ref}></div>
        </>
      )}
    </div>
  );
};

export default ScatterPlot;
