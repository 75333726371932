import Content from "./Content";
import Adaptation from "./Adaptation";

//changer "inondations" partout
import logo from "../../../assets/icons/sol.svg";
export const SolsAgricoles = {
  id: "solsAgricoles",
  label: "Sols Agricoles",
  cluster: "nature",
  clusterLabel: "Nature",
  description: (
    <div>
      <p>
        Les effets du changement climatique sur les sols agricoles sont significatifs, notamment à travers les précipitations extrêmes et les
        sécheresses. Selon le rapport de la{" "}
        <a href="https://plateforme-wallonne-giec.be/assets/documents/Adaptation/PwG-SyntheseAdaptation_2022_complet_rev1.pdf">
          Plateforme Wallonne pour le GIEC (PwG, 2022)
        </a>
        , ces changements pourraient avoir un impact sur la productivité moyenne des cultures ainsi que sur la variabilité interannuelle des
        rendements. Suite à ces aléas, les sols agricoles contribuent également à la pollution par le lessivage des polluants, ce qui peut entraîner
        des répercussions sur d'autres composantes de l'écosystème, en particulier les eaux souterraines.
      </p>
    </div>
  ),

  //compléter
  aleas: [{ name: "Pluies extrêmes" }, { name: "augmentation de température" }],

  facteurs_aggravant: [
    { name: "Technique agricoles" },
    { name: "Compaction des sols" },
    { name: "Usage d’intrants" },
    { name: "Utilisation des sols" },
  ],

  expositions: [{ name: "sols agricoles" }, { name: "Eau de surface" }, { name: "Nappes phréatique" }, { name: "Biodiversité" }],

  vulnérabilités: [
    { name: "Teneur en matière organique" },
    { name: "Texture du sol" },
    { name: "Structure du sol" },
    { name: "Topographie" },
    { name: "Epaisseur du sol" },
    { name: "Couvert végétal" },
    { name: "Dispositifs de rétention d’eau" },
  ],

  impacts: [
    { name: "Pertes de rendements agricoles" },
    { name: "Dégradation des sols agricoles" },
    { name: "Erosion" },
    { name: "Coulées boueuses" },
    { name: "Sédimentation dans les cours d’eau" },
    { name: "Pollution des nappes" },
    { name: "Dégradation des écosystèmes" },
  ],

  content: <Content />,
  adaptation: <Adaptation />,

  //changer
  icon: logo,

  //compléter
  maps: ["", ""],

  //supprimer non pertinent
  associatedThemes: ["agriculture", "biodiversité", "eau"],
};
