import React from "react";
import usePromise from "react-use-promise";

/**
 * @param component path from src folder (eg. components/Explorer/Map)
 * @returns a lazy loaded component
 */
const Lazy: React.FC<{
  component: string;
  props: Record<string, unknown>;
}> = ({ component, props }) => {
  const [Component = null] = usePromise(async () => React.lazy(() => import("../../" + component)), [component]);
  if (Component === null) return null;
  return React.createElement(Component, { ...props });
};

export const LazyWait = ({ ok }) => {
  if (!ok)
    return React.createElement(
      React.lazy(
        () =>
          new Promise((resolve, reject) => {
            reject("LazyWait is loading");
          }),
      ),
      {},
    );
  return null;
};

export default Lazy;
