import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";

export default function Methodologie() {
  //@ts-ignore
  const [event, setEvent] = useContext(EventContext);

  return (
    <div>
      <h4>Approche générale</h4>
      <p>
        Nous proposons pour la Wallonie de développer une approche qui fournit aux acteurs de terrain une cartographie des zones à risques sur base de
        données régionales. Ces cartographies pourront ensuite servir de matière pour une analyse locale plus fine sur base d’avis d’experts comme
        proposé dans les autres régions/pays. En Wallonie la cartographie du réseau écologique (RE) wallon avec modélisation des habitats naturels a
        été réalisée. Il existe également une cartographie du potentiel de services écosystémiques. Intégrer les différents scénarios de projections
        des paramètres climatiques dans ces deux cartographies nous permettra de fournir une base pour les analyses de vulnérabilité. Attention
        certaines attentes du cahier des charges portaient sur un niveau très fin d’analyse, comme l’impact des changements climatiques au niveau des
        haies. L’approche proposée ici fournira des informations plus génériques, mais qui pourront être utilisées dans des diagnostics d’expert au
        niveau local adressant un niveau de détail aussi fin.
      </p>
      <p>
        Deux approches cartographiques seront utilisées pour les trois thématiques concernées, selon qu’on identifie les risques qui pèsent sur les
        habitats ou les risques qui pèsent sur les services que nous rendent les écosystèmes naturels.{" "}
      </p>
      <p>
        La thématique « forêts » sera intégrée aux deux autres thématiques. De plus, une méthodologie de monitoring des actions déjà mises en place
        pour augmenter la résilience des écosystèmes forestiers au changement climatique sera proposée. Celle-ci pourra se baser sur les données de
        l’inventaire forestier wallon pour quantifier la part d’essences en adéquation stationnelle, ainsi que l’évolution de l’état de conservation
        des habitats forestiers en N2000.
      </p>
      <h4>Approche « Espèces et habitats protégés / rares » </h4>
      <p>
        Une modélisation des aires de répartition futures d’une sélection d’habitats et d’espèces sera réalisée (comme suggéré dans le document
        technique du Secrétariat de la Convention sur la Biodiversité Ecologique, 2009). Les habitats seront sélectionnés au sein de la liste des
        végétations naturelles potentielles (VNP), dont au moins un habitat forestier et un habitat lié à l’agriculture. Seront sélectionnés les VNP
        susceptibles d’être fortement impactés par le changement climatique (en répartition ou en qualité d’habitat) en raison des conditions
        écologiques extrêmes qui les caractérisent. La priorité sera donnée aux habitats prioritaires.{" "}
      </p>
      <p>
        La vulnérabilité de chaque VNP au changement climatique sera quantifiée sur base de modélisations avec variation des variables climatiques en
        fonction des différents scénarios, toute autre chose restant égale. Les modèles utilisés seront ceux mis au point dans le cadre du projet
        « Réseau écologique wallon ». Les résultats seront soumis à l’avis d’experts.{" "}
      </p>
      <p>
        Comme pour les habitats, une sélection d’espèces protégées sensibles au changement climatique sera réalisée. Ces espèces seront choisies en
        lien avec les habitats repris dans la première sélection.
      </p>
      <p>
        Une modélisation sera faite de l’aire de distribution spatiale (SDM) des espèces sélectionnées dans un contexte des nouvelles variables
        climatiques selon différents scénarios de changements climatiques. Les résultats seront à nouveau soumis à l’avis d’experts.{" "}
      </p>
      <EventHighlight eventId="test3">
        <p>
          Une analyse de la connectivité Sud-Nord du réseau écologique pour les habitats et espèces sélectionnés (sur modélisation en fonction des
          distances de dispersion et sur avis d’expert) sera réalisé. Cette analyse intègrera des scénarios de changement d’occupation du sol et
          identifiera les grandes zones de rupture de la connectivité.{" "}
        </p>
      </EventHighlight>
      <p>
        Enfin, un état des lieux sera fait des connaissances et projections déjà effectuées à l’échelle belge ou européenne pour des espèces exotiques
        envahissantes par rapport aux changements climatiques. Mobilisation d’experts pour identifier des espèces à risque. Si possible, estimation de
        la dette d'invasion (espèces déjà introduites en voie d’invasion) en utilisant des listes d'espèces naturalisées pour certains groupes
        taxonomiques et en quantifiant la proportion à risque de devenir invasive suite aux changements climatiques.
      </p>
      <h4>Approche « Services Ecosystémiques » </h4>
      <p>
        La récente cartographie wallonne des services écosystémiques servira de base d’analyse. Cette cartographie sera reproduite pour différentes
        situations futures, sur base :{" "}
      </p>
      <ul>
        <li>De nouveaux paramètres climatiques selon les scénarios de changements climatiques </li>
        <li>Des scénarios de modification d’occupation du sol</li>
      </ul>
      <p>
        Ces situations futures seront réalisées grâce à une matrice des services écosystémiques révisée en y ajoutant une dimension pour chaque
        contexte projeté (avis d’expert).
      </p>
      <p>
        Projection des valeurs de la matrice sur les cartographies en fonction des divers scénarios (notamment les variations de distribution des
        habitats identifiés dans la partie « biodiversité »). Certaines de ces projections ne pourront se faire qu’à l’échelle de grandes zones
        bioclimatiques de Wallonie, en fonction des informations dont nous disposerons en terme de modifications d’occupation du sol.{" "}
      </p>
      <p>
        Pour une sélection de services, des analyses plus poussées d’impacts seront menées en lien avec d’autres thématiques, afin d’identifier des
        zones à risque (ZAR) et des zones d’intervention prioritaire (ZIP).
      </p>
    </div>
  );
}
