import bbox from "@turf/bbox";
import geojsonFile_ from "../assets/belgium.json";
import * as topojson from "topojson-client";

export const geojsonFile = geojsonFile_;

export const allProvinceFeatures = topojson.feature(geojsonFile, geojsonFile.objects.provinces);
export const allMunicipalityFeatures = topojson.feature(geojsonFile, geojsonFile.objects.municipalities);

export const geojsonWallonia = topojson.feature(geojsonFile, {
  ...geojsonFile.objects.provinces,
  geometries: geojsonFile.objects.provinces.geometries.filter((d) => d.properties.reg_nis === "03000" /* Région Walonne */),
});

export const geojsonProvinces = allProvinceFeatures.features.filter((d) => d.properties.reg_nis === "03000" /* Région Walonne */);

export const geojsonMunicipalities = allMunicipalityFeatures.features.filter((d) => d.properties.reg_nis === "03000" /* Région Walonne */);
