import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";

export default function Methodologie() {
  //const [event, setEvent] = useContext(EventContext);

  return (
//Décrire les mesures d'adaptation possible en lien avec les vulnérabilités explorées sur les cartes
    <div>
      <p> 
      La résilience juste, soit « ne laisser personne de côté », doit être au centre des plans d’adaptation aux changements climatiques. Ce principe implique de faire en sorte que les mesures d’adaptation mises en place ne créent ou n’augmentent pas la vulnérabilité au sein des groupes vulnérables, sinon on parle de mal adaptation. Ces groupes disposent en effet de moins de ressources afin de faire entendre leur voix dans le débat public. Il est cependant essentiel que leurs besoins soient entendus lors du choix des mesures d’adaptation au risque que ces mesures ne bénéficient qu’à des groupes plus avantagés socio économiquement. 
      <a href="">[(Voir fiche mesure XX)]</a>
      </p>
     
    </div>
  );
}
