import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";

export default function Methodologie() {
  //const [event, setEvent] = useContext(EventContext);

  return (
//Décrire les mesures d'adaptation possible en lien avec les vulnérabilités explorées sur les cartes
    <div>
      <p> 
      Pour tout bâtiment accueillant des personnes sensibles et se trouvant dans une zone d'aléa, il est recommander de mettre en place des mesures adaptées : des plans d'urgence pour les inondations et les feux de forêts et des mesures similaires au <a href="">logement</a> pour lutter contre les ilôts de chaleur urbain.  
      <a href="">[(Voir fiche mesure XX)]</a>
      </p>
     
    </div>
  );
}
