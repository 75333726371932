import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";

export default function Methodologie() {
  //const [event, setEvent] = useContext(EventContext);

  return (
//Décrire les mesures d'adaptation possible en lien avec les vulnérabilités explorées sur les cartes
    <div>
      <p> 
      La libération de polluants atmosphériques par certaines activités humaines spécifiques requière d’agir sur celles-ci pour en réduire l’impact. En ce sens, limiter l’utilisation des moteurs thermiques et favoriser fortement la mobilité active dans la zone la plus large possible permettrait d’améliorer sensiblement la qualité de l’air et de réduire les risques sur la santé. Cela nécessite de prendre des mesures en matière de mobilité notamment réduire l’impact du trafic automobile (instaurer des zones basses émissions, favoriser les transports en commun ou partagés, rendre les voitures électriques accessibles). 
      <a href="">[(Voir fiche mesure XX)]</a>
      Tout en continuant de viser ces activités, d’autres pistes pertinentes peuvent être avancées comme :

      <ul>
        <li>Pérenniser le télétravail ; <a href="">[(Voir fiche mesure XX)]</a></li>
        <li>Rénover et isoler le bâti (bâtiments publics en priorité) ; <a href="">[(Voir fiche mesure XX)]</a></li>
        <li>Réduire la dissémination d’intrants chimiques (pesticides, herbicides et engrais) dans l’environnement ; <a href="">[(Voir fiche mesure XX)]</a></li>
        <li>Accélérer la transition énergétique.</li>
      </ul>

      </p>
     
    </div>
  );
}
