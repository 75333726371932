import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";

export default function Methodologie() {
  //const [event, setEvent] = useContext(EventContext);

  return (
//Décrire les mesures d'adaptation possible en lien avec les vulnérabilités explorées sur les cartes
    <div>
      <p> 
      Les mesures d’adaptation au dérèglement climatique à l’échelle d’une entreprise visent à poursuivre la fourniture de ses produits et services, même en cas d'événements climatiques perturbateurs. 
      La démarche d’adaptation se déroule en deux phases :
      <ul>
        <li>un diagnostic de la vulnérabilité climatique de l’entreprise, liée à la transition ainsi qu’au risque physique potentiels sur les actifs et la chaîne d'approvisionnement ;</li>
        <li>une mise en œuvre de stratégies visant à renforcer la résilience et à assurer la continuité des activités.</li>
        </ul>
        À cet égard, l'ADEME a identifié un ensemble de méthodes et d’outils internationaux à disposition des entreprises pour les appuyer dans la réalisation de leur diagnostic, tels que l’outil « www.clim-ability.eu » développé par la CCI d’Alsace qui permet de poser les bases d’un diagnostic et de prendre conscience des risques ou la norme ISO 14090 publiée en 2019 par l’organisme international de normalisation qui se concentrent sur la vulnérabilité, les impacts et l'évaluation des risques. 
        Ces outils sont très intéressants pour structurer les démarches d’adaptation, depuis la phase de diagnostic jusqu’au suivi- évaluation, en passant par les processus décisionnels et le passage à l’action. 
        Rappelons également la directive européenne « Corporate Sustainability Reporting Directive (CSRD) » relative à la publication d’informations en matière de durabilité par les entreprises. 
        Elle s’inscrit dans la stratégie générale de l’Union européenne vers une transition durable (le « Green Deal ») à travers la mise en exergue de leur responsabilité environnementale. 
        Elle inclut également la nécessité pour une entreprise d’identifier son exposition aux risques climatiques.

      <a href="">[(Voir fiche mesure XX)]</a>
      </p>
     
    </div>
  );
}
