import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { routes } from "./routes";

import "./App.css";
import "antd/dist/reset.css";

export const queryClient = new QueryClient();

const router = createBrowserRouter(routes);

const App = () => (
  <QueryClientProvider client={queryClient}>
    <RouterProvider router={router} />
    {window.location.hostname === "localhost" && <ReactQueryDevtools />}
  </QueryClientProvider>
);
export default App;
