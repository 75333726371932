import React from "react";
import { Row, Col, Divider } from "antd";
import { useThemes } from "../../../hooks/staticThemes";
import { useTheme } from "../../../hooks/theme";
import _ from "lodash";

export const ThemeButton: React.FC<{
  id: string;
  label: string;
  icon: string;
  selected?: boolean;
}> = ({ id, label, icon, selected }) => {
  const [, setTheme] = useTheme();

  return (
    <Col
      span={4}
      style={{
        backgroundColor: selected ? "#E5E5E5" : "transparent",
        borderRadius: 8,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <button
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 8,
          gap: 5,
          border: "none",
          backgroundColor: "transparent",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => {
          setTheme(id);
        }}
      >
        {/* style={{ filter: selected ? "" : "grayscale(100%)" }} */}
        <img src={icon} width={70} alt={id} />
        <strong>{label}</strong>
      </button>
    </Col>
  );
};

const ThemePicker = () => {
  const [selectedTheme] = useTheme();
  const { data: themes = [] } = useThemes();
  return (
    <div style={{ overflowY: "scroll", height: "calc(100% - 24px)" }}>
      <Row justify="center" style={{ marginTop: 16 }}>
        <h1>Impacts</h1>
      </Row>

      {themes &&
        _(themes)
          .groupBy("cluster")
          .map((g, cluster) => (
            <div>
              <Divider orientation="left">{g[0].clusterLabel}</Divider>
              <Row gutter={8} style={{ padding: 8 }}>
                {g.map((theme) => {
                  return <ThemeButton key={theme.id} id={theme.id} label={theme.label} icon={theme.icon} selected={theme.id === selectedTheme?.id} />;
                })}
              </Row>
            </div>
          ))
          .value()}

      {/* <Divider orientation="left">Nature</Divider>
      <Row gutter={8} style={{ padding: 8 }}>
        {themes
          .filter((t) => t.cluster === "nature")
          .map((theme) => {
            return <ThemeButton key={theme.id} id={theme.id} label={theme.label} icon={theme.icon} selected={theme.id === selectedTheme?.id} />;
          })}
      </Row>
      <Divider orientation="left">Service Publiques et activités économiques</Divider>
      <Row gutter={8} style={{ padding: 8 }}>
        {themes
          .filter((t) => t.cluster === "socioEco")
          .map((theme) => {
            return <ThemeButton key={theme.id} id={theme.id} label={theme.label} icon={theme.icon} selected={theme.id === selectedTheme?.id} />;
          })}
      </Row>
      <Divider orientation="left">Infrastructures et industries</Divider>
      <Row gutter={8} style={{ padding: 8 }}>
        {themes
          .filter((t) => t.cluster === "industriel")
          .map((theme) => {
            return <ThemeButton key={theme.id} id={theme.id} label={theme.label} icon={theme.icon} selected={theme.id === selectedTheme?.id} />;
          })}
      </Row> */}
    </div>
  );
};

export default ThemePicker;
