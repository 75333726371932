import Content from "./Content";
import Adaptation from "./Adaptation";

//changer "inondations" partout
import logo from "../../../assets/icons/eco.svg";
export const Economie = {
  id: "economie",
  label: "Economie",
  cluster: "economie",
  clusterLabel: "Economie",
  description: (
    <div>
      <p>
        Le dérèglement climatique et les évènements météorologiques extrêmes qui y sont associés pourraient sérieusement affecter l’économie wallonne.
        Nous explorons ici l'exposition des entreprises des secteurs secondaire et tertiaires aux risques climatiques physiques tels que la hausse
        tendancielle des températures, les vagues de chaleur, les inondations et les épisodes de sécheresse.{" "}
      </p>
    </div>
  ),

  //compléter
  aleas: [{ name: "Vague de chaleur" }, { name: "Inondation" }, { name: "Sécheresse" }],
  expositions: [{ name: "Bâtiments" }, { name: "Travailleurs" }, { name: "Activités secondaires et tertiaires" }],
  vulnérabilités: [{ name: "Composants du bâtiment" }, { name: "Type de travail" }],
  impacts: [{ name: "Santé au travail" }, { name: "Productivité" }, { name: "Dégâts aux infrastructures" }],
  content: <Content />,
  adaptation: <Adaptation />,

  //changer
  icon: logo,

  //compléter
  maps: ["", ""],

  //supprimer non pertinent
  associatedThemes: ["biodiversité", "energie", "infrastructures", "sante", "social", "villes"],
};
