import Content from "./Content";
import Adaptation from "./Adaptation";

//changer "inondations" partout
import logo from "../../../assets/icons/santé.svg";
export const Sante = {
  id: "sante",
  label: "Santé",
  cluster: "sante",
  clusterLabel: "Santé",
  description: (
    <div>
      <p>
        Certains bâtiments méritent une attention particulière en matière d'adaptation. Ce sont notamment les bâtiments qui accueillent des personnes
        en mauvaise santé (hôpitaux), ainsi que les bâtiments accueillant des personnes âgées (maisons de repos, hômes), ou des enfants en bas âge
        (crèches, écoles). Ces personnes peuvent souffrir plus rapidement de vagues de chaleur et sont potentiellement moins en capacité de se
        réfugier dans un endroit sûr en cas inondations ou d'incendie.
      </p>
    </div>
  ),

  //compléter
  aleas: [{ name: "Vagues de chaleur" }, { name: "Inondations" }, { name: "Incendies" }],
  expositions: [{ name: "Hopitaux" }, { name: "Maisons de repos" }, { name: "Crèches" }, { name: "Ecoles" }],
  vulnérabilités: [{ name: "Personnes âgées" }, { name: "Enfants en bas âge" }],
  impacts: [{ name: "Santé" }, { name: "Décès" }],
  content: <Content />,
  adaptation: <Adaptation />,

  //changer
  icon: logo,

  //compléter
  maps: ["", ""],

  //supprimer non pertinent
  associatedThemes: ["agriculture", "biodiversité", "eau", "infrastructures", "social", "villes"],
};
