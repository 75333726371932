import { useMapCompare } from "./mapCompare";
import { useQueryParam, StringParam } from "use-query-params";

export const useScenarioCompare = () => {
  const [mapCompare] = useMapCompare();
  const [scenarioCompare, setScenarioCompare] = useQueryParam("scenarioCompare", StringParam);
  return [
    mapCompare && mapCompare.scenarios.length > 0 ? mapCompare.scenarios.find((t) => t.label === scenarioCompare) || mapCompare.scenarios[0] : null,
    setScenarioCompare,
  ] as const;
};
