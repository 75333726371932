import Content from "./Content";
import Adaptation from "./Adaptation";

//changer "inondations" partout
import logo from "../../../assets/icons/agriculture.svg";
export const AgricultureCultures = {
  id: "rendements_cultures",
  label: "Rendements des Cultures",
  cluster: "agriculture",
  clusterLabel: "Agriculture",
  description: (
    <div>
      <p>
        L’agriculture est une activité dont les rendements sont directement dépendants des conditions météorologiques. Les changements climatiques
        vont donc profondément affecter les rendements des cultures. L’augmentation des températures et de la concentration en CO2 dans l’atmosphère
        pourrait augmenter le rendement de certaines cultures. Toutefois les dégâts liés aux évènements extrêmes et la prolifération des maladies et
        ravageurs auront probablement un impact négatif qui pèsera de manière plus importante.
      </p>
    </div>
  ),

  //compléter
  aleas: [
    { name: "vagues de chaleur" },
    { name: "gèle tardif et hâtif" },
    { name: "précipitations importantes et inondations" },
    { name: "sècheresses prolongées" },
    { name: "augmentation de la concentration atmosphérique en CO2" },
  ],
  facteurs_aggravant: [{ name: "compétition pour l'eau" }, { name: "imperméabilisation des sols" }],
  expositions: [{ name: "cultures" }],
  vulnérabilités: [{ name: "santé de l'écosystème" }, { name: "potentiel de rétention en eau du sol" }, { name: "pratiques agricoles" }],
  impacts: [{ name: "modifications des rendements" }, { name: "calamités agricoles" }],
  content: <Content />,
  adaptation: <Adaptation />,

  //changer
  icon: logo,

  //compléter
  maps: [],

  //supprimer non pertinent
  associatedThemes: ["biodiversité", "sols", "eau"],
};
