import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";

export default function Methodologie() {
  //const [event, setEvent] = useContext(EventContext);

  return (
//Décrire les mesures d'adaptation possible en lien avec les vulnérabilités explorées sur les cartes
    <div>
      <p> 
      Les mesures agroenvironnementales et climatiques (MAEC) offrent aux agriculteurs la possibilité de mettre en place des pratiques et des systèmes de gestion respectueux du climat, incluant notamment l'agroécologie et l'agroforesterie. Cela permet d'augmenter le taux de matière organique dans les sols, de maintenir une couverture végétale et de réduire l'utilisation de produits chimiques, rendant ainsi les sols moins vulnérables à l'érosion et à la désertification.<a href="">[(Voir fiche mesure XX)]</a>
      </p>
      <p> 
      Les pratiques agricoles favorisant la ventilation des sols, la formation d'agrégats et l'accroissement ou le maintien du taux de matière organique sont essentielles, tel le choix de cultures alternatives moins consommatrice en eau et plus couvrantes, avec un enracinement profond. <a href="">[(Voir fiche mesure XX)]</a>
      </p>
      <p> 
      La mise en place d'aménagements anti-érosifs de haies, bandes enherbées et couverture des sols en hiver peut réduire les pertes de sol d'une parcelle à l'autre et limiter les coulées de boue vers les zones urbanisées. <a href="">[(Voir fiche mesure XX)]</a>
      </p>
     
    </div>
  );
}
