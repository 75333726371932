import Content from "./Content";
import Adaptation from "./Adaptation";
import logo from "../../../assets/icons/flooded-house.svg";

export const EauInondation = {
  id: "eau",
  label: "Eau Inondation",
  cluster: "nature",
  clusterLabel: "Nature",
  description: (
    <div>
      <p>En Wallonie, on distingue deux grands types d’inondation, les inondations par débordement, et les inondations par ruissellement.</p>
      <p>
        L’inondation par ruissellement se produit lorsque les eaux de pluie ne peuvent pas ou plus s’infiltrer dans le sol, ruissellent et finissent
        par se concentrer et générer des inondations locales. Ce phénomène survient généralement à la suite de très fortes pluies, de manière
        relativement brève et localisée. Combiné à de l’érosion des sols (ruissellement sur des terres agricoles à nu) cela peut engendrer des coulées
        de boues ayant un grand impact sur les infrastructures en aval.
      </p>
      <p>
        L’inondation par débordement se produit lorsqu’un cours d’eau déborde de son lit habituel. Celle-ci se produit généralement sur un temps plus
        long à la suite de pluies importantes, pas nécessairement extrêmes, mais survenant sur une longue période pluvieuse en continu.{" "}
      </p>
    </div>
  ),
  aleas: [{ name: "pluviosité extrême ou abondante" }],
  expositions: [{ name: "occupation du sol" }, { name: "bassins versants" }],
  vulnérabilités: [
    { name: "couverture du sol" },
    { name: "potentiel de rétention en eau" },
    { name: "capacité de recharge des nappes" },
    { name: "teneur en matière organique du sol" },
    { name: "structure et texture du sol" },
    { name: "pratiques culturales" },
    { name: "topographie et relief" },
    { name: "éléments de retenue d'eau" },
  ],
  impacts: [{ name: "inondation" }, { name: "érosion des sols" }, { name: "coulée boueuse" }, { name: "dégâts aux infrastructures" }],
  facteurs_aggravant: [{ name: "artificialisation des terres" }, { name: "relief" }, { name: "sécheresse édaphique (du sol)" }],
  content: <Content />,
  adaptation: <Adaptation />,
  icon: logo,
  maps: ["AleaInondation", "BassinsVersants"],
  associatedThemes: [
    "agriculture",
    "biodiversité",
    "sols",
    "economie",
    "energie",
    "infrastructures",
    "logements",
    "sante",
    "social",
    "tourisme",
    "villes",
  ],
};
